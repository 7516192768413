<template>
  <v-input
      v-model="matrix"
      :rules="tableRule"
  >
    <v-dialog
        v-model="dialogMap"
        max-width="700"
    >
      <v-card>
        <v-card-text class="pt-4">
          <GmapMap
              :center="location"
              :zoom="zoom"
              :options="mapOptions"
              map-type-id="roadmap"
              class="map"
              ref="gmap"
          >
            <GmapMarker
                :position="markerLocation"
                :clickable="true"
                :draggable="true"
            />
          </GmapMap>
          <v-row class="mt-4">
            <v-col cols="6">
              <v-text-field
                  type="number"
                  :value="markerLocation.lat"
                  :label="$t('latitude')"
                  hide-details
                  dense
                  @change="val => markerLocation.lat = Number(val)"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                  type="number"
                  :value="markerLocation.lng"
                  :label="$t('longitude')"
                  hide-details
                  dense
                  @change="val => markerLocation.lng = Number(val)"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
              color="secondary"
              text
              @click="dialogMap = false"
          >
            {{ $t('button_cancel') }}
          </v-btn>
          <v-spacer />
          <v-btn
              color="info"
              text
              @click="saveMapMarker"
          >
            {{ $t('button_ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-data-table
        :headers="headers"
        :items="matrix"
        :no-data-text="$t('no_locations_added')"
        disable-sort
        disable-pagination
        disable-filtering
        hide-default-footer
        dense
        class="mt-3 fullwidth"
    >
      <template #footer>
        <v-toolbar
            flat
            dense
            color="white"
        >
          <v-spacer />
          <v-dialog
              v-model="dialog"
              max-width="700px"
          >
            <template #activator="{ on }">
              <v-btn
                  fab
                  small
                  color="info"
                  dark
                  v-on="on"
              >
                <v-icon>{{ icons.mdiPlus }}</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-form ref="itemtable">
                <v-card-title>
                  <span class="text-h3">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col
                        cols="12"
                        sm="6"
                    >
                      <v-text-field
                          v-model="editedItem.location"
                          :label="$t('location')"
                          hide-details="auto"
                          outlined
                      />
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                    >
                      <v-text-field
                          v-model="editedItem.products_services"
                          :label="$t('products_services')"
                          hide-details="auto"
                          outlined
                      />
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                    >
                      <v-text-field
                          v-model="editedItem.no_employees"
                          :label="$t('no_employees')"
                          type="number"
                          hide-details="auto"
                          outlined
                      />
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        v-if="question.include && question.include.mapmarker"
                    >
                      <v-text-field
                          v-model="editedItem.mapmarker"
                          :label="$t('map_marker')"
                          hide-details="auto"
                          @click="openMap('mapmarker')"
                          :append-icon="icons.mdiMapMarker"
                          outlined
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                      color="secondary"
                      text
                      @click="close"
                  >
                    {{ $t('button_cancel') }}
                  </v-btn>
                  <v-spacer />
                  <v-btn
                      color="info"
                      text
                      @click="save"
                  >
                    {{ $t('button_save') }}
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template #item.actions="{ item }">
        <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
        >
          {{ icons.mdiPencil }}
        </v-icon>
        <v-icon
            small
            @click="deleteItem(item)"
        >
          {{ icons.mdiDelete }}
        </v-icon>
      </template>
    </v-data-table>
  </v-input>
</template>

<script>
import {mdiPencil, mdiDelete, mdiPlus, mdiMapMarker} from '@mdi/js';

export default {
  name: 'UnitTable',
  props: {
    question: Object,
  },
  data: () => ({
    dialog: false,
    dialogMap: false,
    zoom: 1,
    mapProp: null,
    location: {
      lat: 53,
      lng: 9,
    },
    markerSet: false,
    markerLocation: {
      lat: 53,
      lng: 9,
    },
    matrix: [],
    editedIndex: -1,
    editedItem: {
      location: '',
      products_services: '',
      no_employees: '',
    },
    defaultItem: {
      location: '',
      products_services: '',
      no_employees: '',
    },
    icons: {
      mdiPencil,
      mdiDelete,
      mdiPlus,
      mdiMapMarker,
    },
    mapOptions: {
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUi: true,
      draggableCursor: 'crosshair',
    },
  }),
  computed: {
    requiredRule() {
      const required = this.$t('required');
      return [
        v => (!!v || v === 0) || required
      ];
    },
    tableRule() {
      const required = this.$t('required');
      return [
        v => !!v.length || required
      ];
    },
    headers() {
      return [
        {text: this.$t('location'), value: 'location'},
        {text: this.$t('products_services'), value: 'products_services'},
        {text: this.$t('no_employees'), value: 'no_employees'},
        ...this.question.include?.mapmarker ? [{text: this.$t('map_marker'), value: 'mapmarker'}] : [],
        {text: this.$t('actions'), value: 'actions'},
      ];
    },
    formTitle() {
      return this.editedIndex === -1 ? this.$t('add_location') : this.$t('edit_location');
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogMap(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.gmap.$mapPromise.then(map => {
            if (!this.markerSet) {
              this.getCurrentPosition().then(position => {
                if (position?.coords) {
                  this.location.lat = position.coords.latitude;
                  this.location.lng = position.coords.longitude;
                  this.markerLocation = this.location;
                  this.zoom = 3;
                }
              }).catch(err => {
                console.log(err);
              });
            }
            map.addListener('click', e => {
              this.markerSet = true;
              this.markerLocation = {lat: parseFloat(e.latLng.lat()), lng: parseFloat(e.latLng.lng())};
              if (this.zoom < 16) {
                this.zoom += 1;
              }
              map.panTo(this.markerLocation);
            });
            map.addListener('zoom_changed', e => {
              this.zoom = map.getZoom();
            });
          });
        });
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.matrix = (Array.isArray(this.question.answer)) ? this.question.answer : [];
    },
    openMap(prop) {
      this.markerSet = false;
      if (this.editedItem[prop]) {
        const coord = this.editedItem[prop].split(',').map(i => parseFloat(i.trim()));
        if (coord.length === 2 && !isNaN(coord[0]) && !isNaN(coord[1])) {
          this.location = {
            lat: coord[0],
            lng: coord[1],
          };
          this.markerLocation = this.location;
          this.zoom = 14;
          this.markerSet = true;
        }
      }
      this.mapProp = prop;
      this.dialogMap = true;
    },
    saveMapMarker() {
      if (this.markerSet || (this.markerLocation.lat && this.markerLocation.lng)) {
        this.$set(this.editedItem, this.mapProp, `${this.markerLocation.lat},${this.markerLocation.lng}`);
      }
      this.dialogMap = false;
    },
    getCurrentPosition() {
      return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        } else {
          resolve(false);
        }
      });
    },
    editItem(item) {
      this.editedIndex = this.matrix.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      const index = this.matrix.indexOf(item);
      confirm(this.$t('confirm_delete_row')) && this.matrix.splice(index, 1);
      this.$emit('answer');
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.matrix[this.editedIndex], this.editedItem);
      } else {
        this.matrix.push(this.editedItem);
      }
      this.question.answer = this.matrix;
      this.$emit('answer');
      this.close();
    },
  },
};
</script>

<style scoped>
.map {
  width: 100%;
  height: 50vh;
  background: #f7f8f9;
}

.fullwidth {
  width: 100%;
}
</style>

<template>
  <section id="certificatetable">
    <v-snackbar
        v-model="snack"
        :timeout="timeout"
    >
      {{ snackMessage }}
    </v-snackbar>
    <div
        class="d-flex justify-end"
        v-if="!question.enforced && (Array.isArray(question.uploads) && question.uploads.length === 0)"
    >
      <v-checkbox
          class="label-sm"
          :label="$t('file_unable')"
          v-model="question.skipped"
          hide-details
          @change="$emit('answer')"
      />
    </div>
    <v-dialog
        v-model="dialog"
        max-width="700"
    >
      <v-card>
        <v-form ref="itemtable">
          <v-card-title>
            <span class="text-h3">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                  v-if="question.include.certificate_type || true"
                  cols="12"
                  sm="6"
              >
                <v-combobox
                    v-model="editedItem.type"
                    :items="saqStore.certificateTypes"
                    :prepend-icon="icons.mdiCertificate"
                    :placeholder="$t('placeholder_select_enter_type')"
                    :label="$t('type')"
                    outlined
                    hide-details="auto"
                />
              </v-col>
              <v-col
                  v-if="question.include.certificate_number"
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    v-model.trim="editedItem.number"
                    :label="$t('certificate_number')"
                    :placeholder="$t('placeholder_certificate_number')"
                    :prepend-icon="icons.mdiIdentifier"
                    outlined
                    hide-details="auto"
                />
              </v-col>
              <v-col
                  v-if="question.include.location"
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    v-if="question.include.location"
                    v-model.trim="editedItem.location"
                    :label="$t('location')"
                    :placeholder="$t('placeholder_location')"
                    :prepend-icon="icons.mdiMapMarker"
                    outlined
                    hide-details="auto"
                />
              </v-col>
              <v-col
                  v-if="question.include.expiration_date"
                  cols="12"
                  sm="6"
              >
                <v-dialog
                    ref="datedialog"
                    v-model="dialogDatePicker"
                    :return-value.sync="editedItem.expiration_date"
                    persistent
                    width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                        v-model="editedItem.expiration_date"
                        :label="$t('expiration_date')"
                        :placeholder="$t('placeholder_date')"
                        :prepend-icon="icons.mdiCalendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details="auto"
                    />
                  </template>
                  <v-date-picker
                      v-model="editedItem.expiration_date"
                      first-day-of-week="1"
                      scrollable
                  >
                    <v-spacer />
                    <v-btn
                        text
                        color="primary"
                        @click="dialogDatePicker = false"
                    >
                      {{
                        $t('button_cancel')
                      }}
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.datedialog.save(editedItem.expiration_date)"
                    >
                      {{ $t('button_ok') }}
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
              >
                <div
                    v-if="editedItem.file && question.uploads.find(f => f.id === editedItem.file)"
                    class="d-flex"
                >
                  <v-icon style="margin-right: 9px;">
                    {{ icons.mdiPaperclip }}
                  </v-icon>
                  <v-chip
                      color="info"
                      dark
                      @click="downloadFile(editedItem.file, question.uploads.find(f => f.id === editedItem.file).original)"
                  >
                    {{ question.uploads.find(f => f.id === editedItem.file).original }}
                  </v-chip>
                </div>
                <v-file-input
                    v-else
                    v-model="upload"
                    :loading="uploading"
                    show-size
                    :placeholder="$t('placeholder_file')"
                    :label="$t('certificate_file')"
                    outlined
                    hide-details="auto"
                >
                  <template #progress>
                    <v-progress-linear :value="progress" />
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
                color="secondary"
                text
                @click="close"
            >
              {{ $t('button_cancel') }}
            </v-btn>
            <v-spacer />
            <v-btn
                color="info"
                text
                :disabled="(!editedItem.type && question.include.certificate_type) || (!editedItem.expiration_date && question.require_expiration_date) || (!upload && !editedItem.file)"
                @click="save"
            >
              {{ $t('button_save') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-input
        v-model="matrix"
        :rules="tableRule"
    >
      <v-data-table
          :headers="headers"
          :items="matrix"
          :no-data-text="$t('no_certificates_added')"
          disable-sort
          disable-pagination
          disable-filtering
          hide-default-footer
          dense
          class="mt-3 fullwidth"
      >
        <template #footer>
          <div class="d-flex justify-end">
            <v-btn
                fab
                small
                color="info"
                dark
                :disabled="question.skipped"
                @click="addItem"
            >
              <v-icon>{{ icons.mdiPlus }}</v-icon>
            </v-btn>
          </div>
        </template>
        <template #item.file="{ item }">
          <v-chip
              v-if="item.file && question.uploads.find(f => f.id === item.file)"
              small
              color="info"
              dark
              @click="downloadFile(item.file, question.uploads.find(f => f.id === item.file).original)"
          >
            {{ question.uploads.find(f => f.id === item.file).original }}
          </v-chip>
        </template>
        <template #item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
          >
            {{ icons.mdiPencil }}
          </v-icon>
          <v-icon
              small
              @click="deleteItem(item)"
          >
            {{ icons.mdiDelete }}
          </v-icon>
        </template>
      </v-data-table>
    </v-input>
  </section>
</template>

<script>
/* eslint-disable vue/no-mutating-props */

import {
  mdiPencil,
  mdiDelete,
  mdiPlus,
  mdiCalendar,
  mdiCertificate,
  mdiPaperclip,
  mdiIdentifier,
  mdiMapMarker,
} from '@mdi/js';
import api from '../core-api';
import {getSaqStore} from '../stores/saq';

export default {
  name: 'CertificateTable',
  setup() {
    const saqStore = getSaqStore();
    return {
      saqStore,
    };
  },
  props: {
    question: Object,
    respondent_id: String,
    supplier_id: String,
    saq_id: String,
  },
  data: () => ({
    dialog: false,
    dialogDatePicker: false,
    matrix: [],
    selectedDate: null,
    editedIndex: -1,
    upload: null,
    uploading: false,
    progress: null,
    timeout: 4000,
    snack: false,
    snackMessage: '',
    editedItem: {
      type: '',
      location: '',
      number: '',
      expiration_date: '',
      file: '',
    },
    defaultItem: {
      type: '',
      location: '',
      number: '',
      expiration_date: '',
      file: '',
    },
    icons: {
      mdiPencil,
      mdiDelete,
      mdiPlus,
      mdiCalendar,
      mdiCertificate,
      mdiPaperclip,
      mdiIdentifier,
      mdiMapMarker,
    },
  }),
  computed: {
    requiredRule() {
      const required = this.$t('required');
      return [
        v => (!!v || v === 0) || required,
      ];
    },
    tableRule() {
      const required = this.$t('required');
      const skipped = this.question.skipped;
      return [
        v => skipped || !!v.length || required,
      ];
    },
    headers() {
      let headers = [];
      if (this.question.include.certificate_type) {
        headers.push({text: this.$t('type'), value: 'type'});
      }
      if (this.question.include.location) {
        headers.push({text: this.$t('location'), value: 'location'});
      }
      if (this.question.include.certificate_number) {
        headers.push({text: this.$t('certificate_number'), value: 'number'});
      }
      if (this.question.include.expiration_date) {
        headers.push({text: this.$t('expiration_date'), value: 'expiration_date'});
      }
      headers.push({text: this.$t('file'), value: 'file'});
      headers.push({text: this.$t('actions'), value: 'actions'});
      return headers;
    },
    formTitle() {
      return this.editedIndex === -1 ? this.$t('add_certificate') : this.$t('edit_certificate');
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.matrix = (Array.isArray(this.question.answer)) ? this.question.answer : [];
      if (!this.question.uploads) {
        this.question.uploads = [];
      }
      if (!this.question.include || (this.question.include && Object.keys(this.question.include).length === 0)) {
        this.question.include = {
          certificate_type: true,
          location: true,
          expiration_date: true,
          certificate_number: true,
        };
      }
    },
    uploadProgress(progress) {
      this.progress = progress;
    },
    addItem() {
      this.editedItem = {};
      this.dialog = true;
    },
    editItem(item) {
      this.editedIndex = this.matrix.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    performDeleteItem(item) {
      let upload = this.question.uploads.find(f => f.id === item.file);
      if (item.file && upload) {
        api.deleteFile(item.file, upload.original).then(result => {
          if (!result.success) {
            // Not handled
          }
        }).catch(err => {
          console.error('deleteFile', err);
        }).finally(() => {
          this.question.uploads.splice(this.question.uploads.indexOf(upload), 1);
        });
      }
      const index = this.matrix.indexOf(item);
      this.matrix.splice(index, 1);
      this.$emit('answer');
    },
    deleteItem(item) {
      confirm(this.$t('confirm_delete_row')) && this.performDeleteItem(item);
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = {};
        this.editedIndex = -1;
      });
    },
    async downloadFile(id, original) {
      const url = await api.getSignedUrl(id, original);
      if (url) {
        window.location.href = url;
      }
    },
    save() {
      if (this.upload) {
        this.uploading = true;
        api.storeCertificate(this.upload, this.respondent_id, this.saq_id, this.supplier_id, this.editedItem.type, this.editedItem.location, this.editedItem.number, this.editedItem.expiration_date, this.uploadProgress).then(res => {
          if (res[0].success) {
            this.editedItem.file = res[0].id;
            this.question.uploads.push({
              id: res[0].id,
              original: res[0].original,
            });
            this.updateMatrix();
          } else {
            this.snackMessage = this.$t('notification_upload_failure');
            this.snack = true;
          }
        }).catch(() => {
          this.snackMessage = this.$t('notification_upload_failure');
          this.snack = true;
        }).finally(() => {
          this.uploading = false;
          this.upload = null;
        });
      } else if (this.editedItem.file) {
        this.updateMatrix();
      }
    },
    updateMatrix() {
      if (this.editedIndex > -1) {
        Object.assign(this.matrix[this.editedIndex], this.editedItem);
      } else {
        this.matrix.push(this.editedItem);
      }
      this.question.answer = this.matrix;
      this.$emit('answer');
      this.close();
    },
  },
};
</script>

<style scoped>
.fullwidth {
  width: 100%;
}

.label-sm >>> .v-label {
  font-size: 80% !important;
}

</style>

<template>
  <div
    id="country_selector"
    class="mt-3"
  >
    <v-autocomplete
      v-if="multiple"
      :items="localeCountries"
      item-text="name"
      item-value="code"
      :placeholder="$t('placeholder_countries')"
      :prepend-icon="icons.mdiEarth"
      multiple
      outlined
      chips
      small-chips
      deletable-chips
      v-model="question.answer"
      required
      :rules="requiredRule"
      @change="$emit('answer')"
    />
    <v-autocomplete
      v-if="!multiple"
      :items="localeCountries"
      item-text="name"
      item-value="code"
      :placeholder="$t('placeholder_country')"
      :prepend-icon="icons.mdiEarth"
      outlined
      v-model="question.answer"
      required
      :rules="requiredRule"
      @change="$emit('answer')"
    />
  </div>
</template>

<script>
import Countries from '../data/Countries.json';
import {mdiEarth} from '@mdi/js';

export default {
  props: {
    question: Object,
    multiple: Boolean,
  },
  data: () => ({
    localeCountries: [],
    icons: {
      mdiEarth,
    },
  }),
  mounted() {
    this.localeCountries = Countries.map(i => ({
      name: this.parseCountryName(i),
      code: i.alpha3Code,
    })).sort((a, b) => a.name.localeCompare(b.name));
  },
  computed: {
    requiredRule() {
      const required = this.$t('required');
      return [
        v => (!!v || v === 0) || required
      ];
    },
  },
  methods: {
    parseCountryName(v) {
      return this.$t(`countryNames.${v.alpha3Code.toLowerCase()}`);
    }
  }
}
</script>

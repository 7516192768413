const axios = require('axios').default;

let env = 'global';

const base_url = (window.location.hostname === 'localhost') ? 'http://localhost:3009/core-api' : '/core-api';
const storage_url = (window.location.hostname === 'localhost') ? 'http://localhost:3009/storage' : '/storage';

export default {
    setEnv(e) {
      env = e;
    },
    getEnv() {
        return env;
    },
    storeFiles: async function (files, respondent_id, saq_id, supplier_id, cb) {
        let resp = [];
        try {
            resp = await postFiles(files, 'saq', respondent_id, `saq_id=${saq_id}&supplier_id=${supplier_id}`, cb);
        } catch (error) {
            console.error('CoreApi: storeFiles', respondent_id, saq_id, error)
        }
        return resp;
    },
    storeCertificate: async function (file, respondent_id, saq_id, supplier_id, type, location, number, expiration_date, cb) {
        let resp = [];
        try {
            resp = await postFiles([file], 'certificates', supplier_id, `saq_id=${saq_id}&supplier_id=${supplier_id}&respondent_id=${respondent_id}&type=${type}&location=${location}&number=${number}&expiration_date=${expiration_date}`, cb);
        } catch (error) {
            console.error('CoreApi: storeCertificate', respondent_id, saq_id, error)
        }
        return resp;
    },
    getSignedUrl: async function (id, original) {
        let url = '';
        try {
            const resp = await directStorageCall(`/file/ticket/${id}`);
            const ticket = resp.ticket;
            const config = {
                url: (window.location.hostname === 'localhost') ? 'http://localhost:3000/sign' : '/sign',
                method: 'post',
                data: { id, original, ticket },
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            const response = await axios(config);
            url = `https://store.ethosevaluate.net/file/get/${id}?sign=${response.data ? response.data.sign : ''}`;
        } catch (error) {
            console.error('CoreApi: getSignedUrl', id, error)
        }
        return url;
    },
    deleteFile: async function (id, original) {
        let result = {};
        try {
            const resp = await directStorageCall(`/file/ticket/${id}`);
            const ticket = resp.ticket;
            const config = {
                url: (window.location.hostname === 'localhost') ? 'http://localhost:3000/sign' : '/sign',
                method: 'post',
                data: { id, original, ticket },
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            const response = await axios(config);
            result = await directStorageCall(`/file/delete/${id}`, response.data ? response.data.sign : '');
        } catch (error) {
            console.error('CoreApi: deleteFile', id, error);
        }
        return result;
    }
};

async function getData(path, isEnv, sign) {
    let url;
    if (isEnv) {
        url = base_url + '/' + env + path;
    } else {
        url = base_url + path;
    }
    if (sign) {
        url += '?sign=' + sign;
    }
    try {
        const config = {
            url
        };
        const response = await axios(config);
        return response.data;
    } catch (error) {
        console.error('CoreApi: getData', url, error);
        return [];
    }
}

// async function postData(path, data, isEnv) {
//     let url;
//     if (isEnv) {
//         url = base_url + '/' + env + path;
//     } else {
//         url = base_url + path;
//     }
//     try {
//         const config = {
//             url,
//             method: 'post',
//             data,
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//         };
//         const response = await axios(config);
//         return response.data;
//     } catch (error) {
//         console.error('CoreApi: postData', url, error);
//         return [];
//     }
// }

async function directStorageCall(path, sign) {
    let url = `https://store.ethosevaluate.net${path}`;
    if (sign) {
        url += `?sign=${sign}`;
    }
    try {
        const config = {
            url
        };
        const response = await axios(config);
        return response.data;
    } catch (error) {
        console.error('CoreApi: directStorageCall', url, error);
        return { success: false };
    }
}

async function postFiles(files, pipe, ref, qs, cb) {
    let url = `${storage_url}/files/store/${pipe}/${ref}?${qs}`;
    let data = new FormData();
    for (const file of files) {
        data.append("", file, encodeURIComponent(file.name || file.originalname));
    }
    try {
        const config = {
            url,
            method: 'POST',
            data
        };
        if (cb) {
            config.onUploadProgress = function (progressEvent) {
                cb(Math.round((progressEvent.loaded / progressEvent.total) * 100));
            }.bind(this);
        }
        const response = await axios(config);
        return response.data;
    } catch (error) {
        console.error('CoreApi: postFiles', url, error);
        return [];
    }
}

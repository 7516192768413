<template>
  <v-input
      v-model="matrix"
      :rules="tableRule"
  >
    <v-data-table
        :headers="headers"
        :items="matrix"
        :no-data-text="$t('no_companies_added')"
        disable-sort
        disable-pagination
        disable-filtering
        hide-default-footer
        dense
        class="mt-3 fullwidth"
    >
      <template #footer>
        <v-toolbar
            flat
            dense
            color="white"
        >
          <v-spacer />
          <v-dialog
              v-model="dialog"
              max-width="800px"
          >
            <template #activator="{ on }">
              <v-btn
                  fab
                  small
                  color="info"
                  dark
                  v-on="on"
              >
                <v-icon>{{ icons.mdiPlus }}</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-form ref="itemtable">
                <v-card-title>
                  <span class="text-h3">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col
                        cols="12"
                        sm="6"
                    >
                      <v-text-field
                          v-model.trim="editedItem.company"
                          :rules="requiredRule"
                          :label="$t('company_name')"
                          hide-details="auto"
                          outlined
                      />
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                    >
                      <v-autocomplete
                          :items="localeCountries"
                          item-text="name"
                          item-value="code"
                          :placeholder="$t('country')"
                          :prepend-icon="icons.mdiEarth"
                          v-model="editedItem.country"
                          hide-details="auto"
                          outlined
                      />
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                    >
                      <v-text-field
                          v-model.trim="editedItem.crn"
                          :rules="requiredRule"
                          :label="$t('crn')"
                          hide-details="auto"
                          outlined
                      />
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                    >
                      <v-select
                          :items="relationships"
                          :placeholder="$t('relationship')"
                          v-model="editedItem.relationship"
                          hide-details="auto"
                          outlined
                      />
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        v-if="question.include.comment"
                    >
                      <v-text-field
                          v-model.trim="editedItem.comment"
                          :label="$t('comment')"
                          hide-details="auto"
                          outlined
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                      color="secondary"
                      text
                      @click="close"
                  >
                    {{ $t('button_cancel') }}
                  </v-btn>
                  <v-spacer />
                  <v-btn
                      color="info"
                      text
                      @click="save"
                  >
                    {{ $t('button_save') }}
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template #item.actions="{ item }">
        <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
        >
          {{ icons.mdiPencil }}
        </v-icon>
        <v-icon
            small
            @click="deleteItem(item)"
        >
          {{ icons.mdiDelete }}
        </v-icon>
      </template>
      <template #item.country="{ item }">
        {{ item.country.length === 3 ? localeCountries.find(i => i.code === item.country).name : '' }}
      </template>
      <template #item.relationship="{ item }">
        {{ item.relationship ? relationships.find(i => i.value === item.relationship).text : '' }}
      </template>
    </v-data-table>
  </v-input>
</template>

<script>
import {mdiPencil, mdiDelete, mdiPlus, mdiEarth} from '@mdi/js';
import Countries from '../data/Countries.json';

export default {
  name: 'CompanyTable',
  props: {
    question: Object,
  },
  data: () => ({
    localeCountries: [],
    dialog: false,
    matrix: [],
    editedIndex: -1,
    editedItem: {
      relationship: '',
      company: '',
      country: '',
      crn: '',
      comment: '',
    },
    defaultItem: {
      relationship: '',
      company: '',
      country: '',
      crn: '',
      comment: '',
    },
    icons: {
      mdiPencil,
      mdiDelete,
      mdiPlus,
      mdiEarth,
    }
  }),
  computed: {
    requiredRule() {
      const required = this.$t('required');
      return [
        v => (!!v || v === 0) || required
      ];
    },
    tableRule() {
      const required = this.$t('required');
      return [
        v => !!v.length || required
      ];
    },
    relationships() {
      return [
        {value: 'parent', text: this.$t('relationships.parent') },
        {value: 'subsidiary_direct', text: this.$t('relationships.direct_subsidiary') },
        {value: 'subsidiary_indirect', text: this.$t('relationships.indirect_subsidiary') },
        {value: 'joint_venture', text: this.$t('relationships.joint_venture') },
        {value: 'investor', text: this.$t('relationships.investor') },
        {value: 'other_affiliate', text: this.$t('relationships.other_affiliate') }
      ]
    },
    headers() {
      const headers = [
        {text: this.$t('company_name'), value: 'company'},
        {text: this.$t('country'), value: 'country'},
        {text: this.$t('crn'), value: 'crn'},
        {text: this.$t('relationship'), value: 'relationship'},
        ...this.question.include?.comment ? [{text: this.$t('comment'), value: 'comment'}] : [],
      ];
      headers.push({text: this.$t('actions'), value: 'actions'});
      return headers;
    },
    formTitle() {
      return this.editedIndex === -1 ? this.$t('add_company') : this.$t('edit_company');
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.matrix = (Array.isArray(this.question.answer)) ? this.question.answer : [];
      this.localeCountries = Countries.map(i => ({
        name: this.parseCountryName(i),
        code: i.alpha3Code,
      })).sort((a, b) => a.name.localeCompare(b.name));
    },
    parseCountryName(v) {
      return this.$t(`countryNames.${v.alpha3Code.toLowerCase()}`);
    },
    editItem(item) {
      this.editedIndex = this.matrix.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      const index = this.matrix.indexOf(item);
      confirm(this.$t('confirm_delete_row')) && this.matrix.splice(index, 1);
      this.$emit('answer');
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.matrix[this.editedIndex], this.editedItem);
      } else {
        this.matrix.push(this.editedItem);
      }
      this.question.answer = this.matrix;
      this.$emit('answer');
      this.close();
    },
  },
};
</script>

<style scoped>
.fullwidth {
  width: 100%;
}
</style>

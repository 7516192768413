const axios = require('axios').default;

let env = 'global';
let saqId = '';
let supplierId = '';

const base_url = (window.location.hostname === 'localhost') ? 'http://localhost:3009/inside-api' : '/inside-api';
// const base_url = 'https://inside-api.ethosevaluate.net';

const setSaqId = (saq_id) => saqId = saq_id;
const setSupplierId = (supplier_id) => supplierId = supplier_id;

const getData = async (path) => {
    const url = base_url + path;
    try {
        const config = {
            url, headers: {
                ...supplierId && {'X-Supplier-Id': supplierId},
                ...saqId && {'X-Saq-Id': saqId},
            },
        };
        const response = await axios(config);
        return response.data;
    } catch (error) {
        console.error('InsideApi: getData', url, error);
        return {};
    }
};

const postData = async (path, data) => {
    const url = base_url + path;
    try {
        const config = {
            url,
            method: 'post',
            data,
            headers: {
                'Content-Type': 'application/json',
                ...supplierId && {'X-Supplier-Id': supplierId},
                ...saqId && {'X-Saq-Id': saqId},
            },
        };
        const response = await axios(config);
        return response.data;
    } catch (error) {
        console.error('InsideApi: postData', url, error);
        return {};
    }
};

export default {
    getRespondent: async function (respondent_id) {
        let resp = {};
        try {
            resp = await getData(`/v1/saq/respondent/${respondent_id}`);
        } catch (error) {
            console.error('InsideApi: getRespondent', respondent_id, error);
        }
        return resp;
    },
    saveRespondent: async function (respondent_id, respondent, areas) {
        let resp = {
            success: false,
        };
        try {
            respondent.data = areas;
            respondent.ts_saved = new Date();
            resp = await postData(`/v1/saq/respondent/${respondent_id}`, respondent);
        } catch (error) {
            console.error('InsideApi: saveRespondent', respondent_id, error);
        }
        return resp;
    },
    submitQuestionnaire: async function (respondent, areas) {
        let resp = {
            success: false,
        };
        try {
            respondent.data = areas;
            resp = await postData(`/v1/saq/submit/${respondent?.id}`, respondent);
        } catch (error) {
            console.error('InsideApi: submitQuestionnaire', respondent?.id, error);
        }
        return resp;
    },
    getIndexDict: async function () {
        let dict = {};
        try {
            dict = await getData('/v1/country-index/dict');
        } catch (error) {
            console.error('InsideApi: getIndexDict', error);
        }
        return dict;
    },
    getNaceCodes: async function (lang) {
        let dict = {};
        try {
            dict = await getData(`/v1/classifications/nace/${lang}`);
        } catch (error) {
            console.error('InsideApi: getNaceCodes', error);
        }
        return dict;
    },
    getCertificateTypes: async function (lang) {
        let dict = {};
        try {
            dict = await getData(`/v1/classifications/certificate-types/${lang}`);
        } catch (error) {
            console.error('InsideApi: getCertificateTypes', error);
        }
        return dict;
    },
    getTexts: async function (saq_id, lang) {
        let texts = {};
        try {
            texts = await getData(`/v1/saq/texts/${saq_id}?lang=${lang}&dict=true`);
        } catch (error) {
            console.error('InsideApi: getTexts', saq_id, error)
        }
        return texts;
    },
    getDefinition: async function (saq_id) {
        let def = {};
        try {
            def = await getData(`/v1/saq/definition/${saq_id}`);
        } catch (error) {
            console.error('InsideApi: getDefinition', saq_id, error)
        }
        return def;
    },
    getContentTranslation: async function (saq_id, lang) {
        let resp = {};
        try {
            resp = await getData(`/v1/language/content-translations/${saq_id}/${lang}`);
        } catch (error) {
            console.error('InsideApi: getContentTranslation', lang, error);
        }
        return resp;
    },
    setSaqId,
    setSupplierId,
};



import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '../sass/overrides.sass';
import colorTokens from '../assets/tokens/stratsys-palette.json';

Vue.use(Vuetify);

const theme = {
  primary: colorTokens['brand-core-500'],
  secondary: colorTokens['brand-neutral-400'],
  accent: colorTokens['ai-accent-500'],
  info: colorTokens['ai-accent-500'],
  warning: colorTokens['purpose-risks-400'],
  error: colorTokens['purpose-risks-600'],
  success: colorTokens['purpose-risks-100'],
}

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
  },
  theme: {
    themes: {
      light: theme,
      dark: theme
    }
  }
});

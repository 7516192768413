<template>
  <div
      :id="question.id"
      class="question my-3"
  >
    <v-dialog
        v-model="dialogDeleteFile"
        max-width="400px"
    >
      <v-card>
        <v-card-title>{{ $t('delete_file') }}</v-card-title>
        <v-card-text>{{ this.$t('confirm_generic') }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
              color="info"
              text
              @click="dialogDeleteFile = false"
          >
            {{ $t('button_no') }}
          </v-btn>
          <v-btn
              color="secondary"
              text
              @click="deleteFile(target_file_id, target_file_original)"
          >
            {{
              $t('button_yes')
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <h2
        class="question-title mb-3"
        v-html="parseQuestionText(translate(`${question.id}:q`, question.question))"
    />
    <div
        v-if="question.explanation"
        class="question-explanation my-3"
        v-html="translate(`${question.id}:e`, question.explanation)"
    />
    <v-text-field
        v-if="question.type === 'text'"
        v-model.trim="question.answer"
        :placeholder="$t('placeholder_text_answer')"
        :prepend-icon="icons.mdiAlphabetical"
        :rules="(question.fieldtype === 'email') ? emailRule : requiredRule"
        :type="question.fieldtype || 'text'"
        class="mt-3"
        outlined
        required
        @change="handleAnswer(false)"
        :disabled="question.disabled"
    />
    <v-text-field
        v-if="question.type === 'number'"
        v-model.number="question.answer"
        :append-icon="question.percent ? icons.mdiPercent : null"
        :placeholder="$t('placeholder_number_answer')"
        :prepend-icon="icons.mdiNumeric"
        :rules="requiredRule"
        class="mt-3"
        outlined
        required
        step="any"
        type="number"
        @change="handleAnswer(false)"
        :disabled="question.disabled"
    />
    <v-slider
        v-if="question.type === 'slider'"
        v-model="question.answer"
        :append-icon="question.percent ? icons.mdiPercent : null"
        :max="question.max || 100"
        :min="question.min || 0"
        :rules="requiredRule"
        class="mt-10"
        thumb-label="always"
        @change="handleAnswer(false)"
        :disabled="question.disabled"
    />
    <v-dialog
        v-if="question.type === 'date'"
        ref="datedialog"
        v-model="dialogDatePicker"
        :return-value.sync="question.answer"
        persistent
        width="290px"
    >
      <template #activator="{ on, attrs }">
        <v-text-field
            v-model="question.answer"
            :label="$t('date')"
            :placeholder="$t('placeholder_date')"
            :prepend-icon="icons.mdiCalendar"
            readonly
            v-bind="attrs"
            v-on="on"
        />
      </template>
      <v-date-picker
          v-model="question.answer"
          first-day-of-week="1"
          scrollable
          :disabled="question.disabled"
      >
        <v-spacer />
        <v-btn
            color="primary"
            text
            @click="dialogDatePicker = false"
        >
          {{
            $t('button_cancel')
          }}
        </v-btn>
        <v-btn
            color="primary"
            text
            @click="$refs.datedialog.save(question.answer)"
        >
          {{ $t('button_ok') }}
        </v-btn>
      </v-date-picker>
    </v-dialog>
    <v-textarea
        v-if="question.type === 'longtext'"
        v-model.trim="question.answer"
        :placeholder="$t('placeholder_text_answer')"
        :prepend-icon="icons.mdiAlphabetical"
        :rules="requiredRule"
        auto-grow
        class="mt-3"
        outlined
        required
        rows="4"
        @change="handleAnswer(false)"
        :disabled="question.disabled"
    />
    <v-radio-group
        v-if="question.type === 'bool'"
        v-model="question.answer"
        :rules="boolRule"
        required
        row
        @change="handleAnswer(false)"
        :disabled="question.disabled"
    >
      <v-radio
          :label="$t('yes')"
          :value="true"
      />
      <v-radio
          :label="$t('no')"
          :value="false"
      />
    </v-radio-group>
    <div
        v-if="question.type === 'mc'"
        class="checkbox-group mb-8"
    >
      <v-checkbox
          v-for="(option, i) of question.options"
          :key="i"
          v-model="mc"
          :disabled="(!!singleChoice && option.title !== singleChoice) || question.disabled"
          :label="translate(`${question.id}:o:${i}`, option.title)"
          :rules="arrayRule"
          :value="option.title"
          hide-details
          @change="mcAnswer"
      />
    </div>
    <v-radio-group
        v-if="question.type === 'sc'"
        v-model="question.answer"
        :rules="requiredRule"
        required
        @change="handleAnswer(false)"
        :disabled="question.disabled"
    >
      <v-radio
          v-for="(option, i) of question.options"
          :key="i"
          :label="translate(`${question.id}:o:${i}`, option.title)"
          :value="option.title"
      />
    </v-radio-group>
    <v-select
        v-if="question.type === 'select'"
        v-model="question.answer"
        :item-text="v => translate(`${question.id}:o:${v.index}`, v.title)"
        :items="question.options"
        :multiple="question.multiselect"
        :placeholder="$t('placeholder_select')"
        :prepend-icon="icons.mdiFormSelect"
        :rules="requiredRule"
        class="mt-3"
        item-value="title"
        outlined
        required
        @change="handleAnswer(false)"
        :disabled="question.disabled"
    />
    <v-autocomplete
        v-if="question.type === 'sectors'"
        v-model="question.answer"
        :items="sectors"
        :label="$t('sectors')"
        :prepend-icon="icons.mdiFormSelect"
        :rules="arrayRule"
        chips
        class="mt-3"
        deletable-chips
        multiple
        outlined
        small-chips
        @change="handleAnswer(false)"
        :disabled="question.disabled"
    />
    <certificate
        v-if="question.type === 'certificate'"
        :months="months"
        :question="question"
        :respondent_id="respondent_id"
        :saq_id="saq_id"
        :supplier_id="supplier_id"
        @answer="handleAnswer(false)"
    />
    <v-chip-group
        v-if="question.type === 'files' && Array.isArray(question.uploads) && question.uploads.length"
        :key="filesKey"
        class="mb-3"
        column
    >
      <v-chip
          v-for="upload in question.uploads"
          :key="upload.id"
          close
          color="info"
          small
          @click="downloadFile(upload.id, upload.original)"
          @click:close="confirmFileDelete(upload.id, upload.original)"
      >
        {{ upload.original }}
      </v-chip>
    </v-chip-group>
    <div
        v-if="question.type === 'files' && !!question.single"
        class="pb-4"
    >
      <v-file-input
          v-model="file"
          :disabled="question.skipped || question.disabled || question.uploads.length === 1"
          :label="$t('file')"
          :loading="uploading"
          :placeholder="$t('placeholder_file')"
          chips
          class="mt-3"
          hide-details
          outlined
          show-size
          @change="uploadFiles(true)"
      >
        <template #progress>
          <v-progress-linear :value="progress" />
        </template>
      </v-file-input>
      <div
          v-if="!question.enforced && (Array.isArray(question.uploads) && question.uploads.length === 0)"
          class="d-flex justify-end"
      >
        <v-checkbox
            v-model="question.skipped"
            :label="$t('file_unable')"
            class="label-sm"
            hide-details
            @change="handleAnswer(false)"
        />
      </div>
    </div>
    <div
        v-else-if="question.type === 'files'"
        class="pb-4"
    >
      <v-file-input
          v-model="files"
          :disabled="question.skipped || question.disabled"
          :label="$t('files')"
          :loading="uploading"
          :placeholder="$t('placeholder_files')"
          chips
          class="mt-3"
          hide-details
          multiple
          outlined
          show-size
          @change="uploadFiles(false)"
      >
        <template #progress>
          <v-progress-linear :value="progress" />
        </template>
      </v-file-input>
      <div
          v-if="!question.enforced && (Array.isArray(question.uploads) && question.uploads.length === 0)"
          class="d-flex justify-end"
      >
        <v-checkbox
            v-model="question.skipped"
            :label="$t('file_unable')"
            class="label-sm"
            hide-details
            @change="handleAnswer(false)"
        />
      </div>
    </div>
    <country-selector
        v-if="question.type === 'country'"
        :multiple="false"
        :question="question"
        @answer="handleAnswer(false)"
    />
    <country-selector
        v-if="question.type === 'countries'"
        :multiple="true"
        :question="question"
        @answer="handleAnswer(false)"
    />
    <nace-code-selector
        v-if="question.type === 'nace'"
        :question="question"
        @answer="handleAnswer(false)"
    />
    <list-count
        v-if="question.type === 'listcount'"
        :question="question"
        @answer="handleAnswer(false)"
    />
    <custom-table
        v-if="question.type === 'customtable'"
        :question="question"
        :respondent_id="respondent_id"
        :saq_id="saq_id"
        :supplier_id="supplier_id"
        @answer="handleAnswer(false)"
    />
    <unit-table
        v-if="question.type === 'unittable'"
        :question="question"
        @answer="handleAnswer(false)"
    />
    <principal-table
        v-if="question.type === 'principaltable'"
        :question="question"
        @answer="handleAnswer(false)"
    />
    <supplier-table
        v-if="question.type === 'suppliers'"
        :question="question"
        @answer="handleAnswer(false)"
    />
    <company-table
        v-if="question.type === 'companytable'"
        :question="question"
        @answer="handleAnswer(false)"
    />
    <owner-table
        v-if="question.type === 'ownertable'"
        :question="question"
        @answer="handleAnswer(false)"
    />
    <certificate-table
        v-if="question.type === 'certificatetable'"
        :question="question"
        :respondent_id="respondent_id"
        :saq_id="saq_id"
        :supplier_id="supplier_id"
        @answer="handleAnswer(false)"
    />
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */

import CountrySelector from './CountrySelector';
import ListCount from './ListCount';
import CustomTable from './CustomTable';
import UnitTable from './UnitTable';
import Certificate from './Certificate';
import CertificateTable from './CertificateTable';
import PrincipalTable from './PrincipalTable';
import CompanyTable from './CompanyTable';
import SupplierTable from './SupplierTable';
import OwnerTable from './OwnerTable';
import NaceCodeSelector from './NaceCodeSelector';
import api from '@/core-api';
import {mdiCheck, mdiNumeric, mdiAlphabetical, mdiCalendar, mdiFormSelect, mdiPercent} from '@mdi/js';
import bag from '@/data/BusinessActivityGroups.json';
import Translation from '@/mixins/translation';

export default {
  name: 'SaqQuestion',
  mixins: [
    Translation
  ],
  props: {
    question: Object,
    subquestion: Boolean,
    respondent_id: String,
    supplier_id: String,
    saq_id: String,
    indexDict: Object,
    company: String,
    language: String,
    context: {
      type: Object, default: () => {
      }
    },
    locked: {type: Array, default: () => []},
  },
  created() {
    if ((this.question.type.includes('table') || this.question.type === 'suppliers') && !this.question.includes) {
      this.question.includes = {};
    }
    if (this.question.answer && this.question.type === 'mc') {
      if (Array.isArray(this.question.answer)) {
        this.question.answer = this.question.answer.filter(i => !!i);
        this.mc = this.question.answer;
      } else {
        this.question.answer = [];
      }
    }
    if (this.question.type === 'select' && !!this.question.multiselect && !this.question.answer) {
      this.question.answer = [];
    }
    if (this.question.type === 'files') {
      if (!Array.isArray(this.question.answer)) {
        this.question.answer = [];
        this.question.uploads = [];
      }
    }
    if (!this.question.answer && this.question.tag && Object.keys(this.context).includes(this.question.tag)) {
      this.question.answer = this.context[this.question.tag];
      this.question.disabled = this.locked.includes(this.question.tag) && !!(this.context[this.question.tag] || this.context[this.question.tag] === 0 || this.context[this.question.tag] === false);
    }
    if (Array.isArray(this.question.options)) {
      for (let idx = 0; idx < this.question.options.length; idx++) {
        this.question.options[idx].index = idx;
      }
    }
  },
  components: {
    CountrySelector,
    ListCount,
    CustomTable,
    UnitTable,
    Certificate,
    CertificateTable,
    PrincipalTable,
    SupplierTable,
    CompanyTable,
    OwnerTable,
    NaceCodeSelector,
  },
  data: () => ({
    sectors: bag,
    singleChoice: null,
    mc: [],
    files: [],
    file: undefined,
    uploading: false,
    progress: 0,
    dialogDeleteFile: false,
    dialogDatePicker: false,
    selectedDate: null,
    filesKey: 0,
    target_file_id: '',
    target_file_original: '',
    icons: {
      mdiCheck,
      mdiNumeric,
      mdiAlphabetical,
      mdiCalendar,
      mdiFormSelect,
      mdiPercent
    },
  }),
  computed: {
    requiredRule() {
      const required = this.$t('required');
      return [
        v => (!!v || v === 0) || required
      ];
    },
    boolRule() {
      const required = this.$t('required');
      return [
        v => v != null || required
      ];
    },
    arrayRule() {
      const required = this.$t('required');
      return [
        v => !!v.length || required
      ];
    },
    emailRule() {
      const required = this.$t('required');
      const must_be_valid = this.$t('must_be_valid');
      return [
        v => !!v || required,
        v => /.+@.+\..+/.test(v) || must_be_valid,
      ];
    },
    iscontext() {
      return this.question.tag && Object.keys(this.context).includes(this.question.tag);
    },
    uploadPending() {
      return !!this.file || (Array.isArray(this.files) && this.files.length > 0);
    },
    months() {
      return [
        this.$t('jan'),
        this.$t('feb'),
        this.$t('mar'),
        this.$t('apr'),
        this.$t('may'),
        this.$t('jun'),
        this.$t('jul'),
        this.$t('aug'),
        this.$t('sep'),
        this.$t('oct'),
        this.$t('nov'),
        this.$t('dec'),
      ];
    },
  },
  methods: {
    parseQuestionText(text) {
      text = text.replace(/\[CUST_COMPANY]/g, this.company);
      text = text.replace(/\[CLIENT_COMPANY]/g, this.company);
      return text;
    },
    mcAnswer() {
      const single = this.mc.find(i => this.question.options.some(o => o.title === i && o.single_choice));
      if (single) {
        this.mc = [single];
        this.singleChoice = single;
      } else {
        this.singleChoice = null;
      }
      this.question.answer = this.mc.filter(i => !!i);
      this.handleAnswer(false);
    },
    handleAnswer(triggerSave) {
      this.question.subs_required = false;
      if (this.question.index_matches) {
        this.question.index_matches = {};
      }
      let answerTags = [];
      if (this.question.type === 'bool') { // Must go first, since bool questions could have options
        if (this.question.answer === true) {
          if (this.question.require_subs_true) {
            this.question.subs_required = true;
          }
          if (Array.isArray(this.question.answer_tags_true)) {
            answerTags = answerTags.concat(this.question.answer_tags_true);
          }
        } else if (this.question.answer === false) {
          if (this.question.require_subs_false) {
            this.question.subs_required = true;
          }
          if (Array.isArray(this.question.answer_tags_false)) {
            answerTags = answerTags.concat(this.question.answer_tags_false);
          }
        }
      } else if (this.question.type === 'number' || this.question.type === 'slider') {
        const number = parseInt(this.question.answer);
        const upper_limit = parseInt(this.question.upper_limit);
        const lower_limit = parseInt(this.question.lower_limit);
        if (!isNaN(number)) {
          if (Array.isArray(this.question.answer_tags_over) && !isNaN(upper_limit) && number > upper_limit) {
            answerTags = answerTags.concat(this.question.answer_tags_over);
          } else if (Array.isArray(this.question.answer_tags_under) && !isNaN(lower_limit) && number < lower_limit) {
            answerTags = answerTags.concat(this.question.answer_tags_under);
          }
        }
      } else if ((this.question.type === 'country' || this.question.type === 'countries') && this.question.country_groups && Array.isArray(this.question.country_groups) && this.question.country_groups.length) {
        for (const group of this.question.country_groups) {
          if (Array.isArray(this.question.answer)) {
            for (const item of this.question.answer) {
              if ((Array.isArray(group.countries) && group.countries.includes(item)) || (group.preset && this.indexMatch(item, group.preset))) {
                if (group.require_subs && !this.subquestion) {
                  this.question.subs_required = true;
                }
                if (Array.isArray(group.answer_tags) && group.answer_tags.length) {
                  answerTags = answerTags.concat(group.answer_tags);
                }
              }
            }
          } else {
            if ((Array.isArray(group.countries) && group.countries.includes(this.question.answer)) || (group.preset && this.indexMatch(this.question.answer, group.preset))) {
              if (group.require_subs && !this.subquestion) {
                this.question.subs_required = true;
              }
              if (Array.isArray(group.answer_tags) && group.answer_tags.length) {
                answerTags = answerTags.concat(group.answer_tags);
              }
            }
          }
        }
      } else if (this.question.options && Array.isArray(this.question.options) && this.question.options.length) {
        for (const option of this.question.options) {
          if (Array.isArray(this.question.answer)) {
            for (const item of this.question.answer) {
              if (item === option.title) {
                if (option.require_subs && !this.subquestion) {
                  this.question.subs_required = true;
                }
                if (Array.isArray(option.answer_tags) && option.answer_tags.length) {
                  answerTags = answerTags.concat(option.answer_tags);
                }
              }
            }
          } else {
            if (this.question.answer === option.title) {
              if (option.require_subs && !this.subquestion) {
                this.question.subs_required = true;
              }
              if (Array.isArray(option.answer_tags) && option.answer_tags.length) {
                answerTags = answerTags.concat(option.answer_tags);
              }
            }
          }
        }
      } else {
        this.question.subs_required = !!(this.question.subquestions && Array.isArray(this.question.subquestions) && this.question.subquestions.length);
      }
      this.$emit('answer', triggerSave, this.question.subs_required, this.question.id, answerTags);
    },
    indexMatch(answer, preset) {
      let match = false;
      const score = this.indexDict[answer];
      let indexName, operation, v1, v2;
      const matchBetween = preset.match(/^(\w+)_(\d+)_(\d+)$/);
      if (Array.isArray(matchBetween)) { // e.g. gac_70_80
        indexName = String(matchBetween[1]).toLowerCase();
        v1 = Number(matchBetween[2]);
        v2 = Number(matchBetween[3]);
      } else {
        const matchThreshold = preset.match(/^(\w+)_(\w+)_(\d+)$/); // e.g. gac_lt_70;
        if (Array.isArray(matchThreshold)) {
          indexName = String(matchThreshold[1]).toLowerCase();
          operation = String(matchThreshold[2]).toLowerCase();
          v1 = Number(matchThreshold[3]);
        }
      }
      if (indexName === 'wide' && !operation && score.wide >= v1 && score.wide <= v2) {
        match = true;
      } else if (indexName === 'wide' && operation === 'lt' && score.wide < v1) {
        match = true;
      } else if (indexName === 'wide' && operation === 'gt' && score.wide > v1) {
        match = true;
      } else if (indexName === 'gac' && !operation && score.gac >= v1 && score.gac <= v2) {
        match = true;
      } else if (indexName === 'gac' && operation === 'lt' && score.gac < v1) {
        match = true;
      } else if (indexName === 'gac' && operation === 'gt' && score.gac > v1) {
        match = true;
      }
      return match;
    },
    async downloadFile(id, original) {
      const url = await api.getSignedUrl(id, original);
      if (url) {
        window.location.href = url;
      }
    },
    confirmFileDelete(id, original) {
      this.target_file_id = id;
      this.target_file_original = original;
      this.dialogDeleteFile = true;
    },
    deleteFile(id, original) {
      this.dialogDeleteFile = false;
      api.deleteFile(id, original).then(result => {
        if (!result.success) {
          // Not handled
        }
      }).catch(err => {
        console.error('deleteFile', err);
      }).finally(() => {
        for (let i = 0; i < this.question.uploads.length; i += 1) {
          if (this.question.uploads[i].id === id) {
            this.question.uploads.splice(i, 1);
          }
        }
        this.question.answer = [];
        for (let i = 0; i < this.question.uploads.length; i += 1) {
          this.question.answer.push(this.question.uploads[i].id);
        }
        this.handleAnswer();
        this.filesKey += 1;
      });
    },
    uploadProgress(progress) {
      this.progress = progress;
    },
    async uploadFiles(single) {
      if (this.uploadPending) {
        this.uploading = true;
        const files = single ? [this.file] : this.files;
        let result = await api.storeFiles(files, this.respondent_id, this.saq_id, this.supplier_id, this.uploadProgress);
        if (result.length) {
          this.files = [];
          this.file = undefined;
          let succeeded = true;
          for (const item of result) {
            if (item.success) {
              this.question.uploads.push({
                id: item.id,
                original: item.original,
              });
              this.question.answer.push(item.id);
            } else {
              succeeded = false;
            }
          }
          if (succeeded) {
            this.$emit('notify', this.$t('notification_upload_success'));
          } else {
            this.$emit('notify', this.$t('notification_upload_failure'));
          }
        }
        this.handleAnswer(true);
        this.uploading = false;
        this.filesKey += 1;
      }
    }
  },
}
</script>

<style scoped>
.question-title {
  font-weight: 400;
  font-size: 1.15rem;
  color: rgba(0, 0, 0, .8);
}

.question-explanation {
  font-style: italic;
  font-size: .875rem;
  color: rgba(0, 0, 0, .6);
}

.label-sm >>> .v-label {
  font-size: 80% !important;
}
</style>

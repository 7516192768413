<template>
  <v-app
      id="saq"
      dark
  >
    <v-app-bar
        app
        color="#fff"
        elevation="3"
        clipped-left
        dense
    >
      <v-toolbar-title v-text="$t('saq')" />
      <v-spacer />
      <v-menu
          v-if="isMobile && supportedLanguages.length > 1"
          close-on-content-click
          bottom
          left
          offset-y
      >
        <template #activator="{ on, attrs }">
          <v-btn
            icon
            v-on="on"
            v-bind="attrs"
          >
            <v-icon>{{ icons.mdiTranslate }}</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item-group
            v-model="language"
            @change="setLocale"
          >
            <v-list-item
                v-for="lang of supportedLanguages"
                :key="lang.code"
                :value="lang.code"
            >
              <v-list-item-title>{{ lang.nativeName }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <v-select
          v-else-if="supportedLanguages.length > 1"
          :items="supportedLanguages"
          v-model="language"
          item-text="nativeName"
          item-value="code"
          @change="setLocale"
          outlined
          dense
          class="mt-6"
          style="max-width: 144px"
      />
    </v-app-bar>

    <v-main>
      <v-container
          class="fill-height"
          fluid
      >
        <v-row
            align="center"
            justify="center"
        >
          <v-col
              cols="12"
              sm="10"
              class="text-center"
          >
            <p class="text-h1">
              {{ $t('welcome') }}
            </p>
            <p class="text-subtitle-1">
              {{ $t('instruction_texts.signin') }}
            </p>
            <v-alert
                v-if="isInvalid"
                type="error"
            >
              {{ $t('error_texts.incorrect_id') }}
            </v-alert>
            <v-alert
                v-if="isExpired"
                type="error"
            >
              {{ $t('error_texts.expired') }}
            </v-alert>
            <v-alert
                v-if="isError"
                type="error"
            >
              {{ $t('error_texts.general') }}
            </v-alert>
            <div class="my-3">
              <v-text-field
                  v-model.trim="respondent_id"
                  label="ID"
                  placeholder="Respondent ID"
                  outlined
                  :rules="idRule"
                  required
                  @change="valid_id = null"
                  @keyup="valid_id = null"
                  @keyup.enter="start()"
              />
              <v-btn
                  :loading="loading"
                  outlined
                  large
                  color="info"
                  @click="start"
              >
                {{ $t('button_start') }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
</v-app>
</template>

<script>
import router from '../router';
import api from '../core-api';
import insideApi from '../inside-api';
import Languages from '../data/Languages.json';
import {mdiTranslate} from '@mdi/js';

export default {
  name: 'Start',
  data: () => ({
    title: 'Self-assessment questionnaire',
    saq_id: '',
    respondent_id: '',
    respondent: {},
    addedContext: {},
    language: 'en',
    supportedLanguages: Languages.sort((a, b) => a.nativeName > b.nativeName && 1 || -1),
    preview: false,
    readOnly: false,
    loading: false,
    def: {},
    isInvalid: false,
    isError: false,
    isExpired: false,
    isMobile: window.innerWidth < 600,
    icons: {
      mdiTranslate,
    }
  }),
  computed: {
    idRule() {
      const required = this.$t('required');
      return [
        v => (!!v || v === 0) || required
      ];
    },
    init_id() {
      return this.$route.params.id;
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize, { passive: true });
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true });
    }
  },
  created() {
    if (this.$route.query.mode) {
      if (this.$route.query.mode.toLowerCase() === 'preview') {
        this.preview = true;
      } else if (this.$route.query.mode.toLowerCase() === 'view') {
        this.readOnly = true;
      }
    }
    if (this.$route.query && Object.keys(this.$route.query).length > 0) {
      this.addedContext = {...this.$route.query};
      delete this.addedContext.mode;
    }
    if (this.init_id) {
      this.respondent_id = (this.preview) ? 'preview' : this.init_id;
      this.start();
    }
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600;
    },
    getRespondent() {
      return new Promise(resolve => {
        if (this.preview) {
          resolve({
            id: 'preview',
            saq_id: this.init_id,
            status: 'invited',
            client_id: 1,
            supplier_id: 1,
          });
        } else {
          insideApi.getRespondent(this.respondent_id).then(respondent => {
            resolve(respondent);
          }).catch(err => {
            resolve({});
          });
        }
      });
    },
    setLocale(lang) {
      if (!lang) lang = this.language;
      if (lang === 'no' || lang === 'nn') { // Norwegian variants, only nb is supported in i18n
        lang = 'nb';
      }
      if (this.supportedLanguages.find(i => i.code === lang)) {
        this.$i18n.locale = lang;
        const html = document.documentElement;
        const metaContentLanguage = document.getElementById('metaContentLanguage');
        html.setAttribute('lang', lang);
        metaContentLanguage.setAttribute('content', lang);
      }
    },
    async start() {
      if (this.respondent_id) {
        this.loading = true;
        try {
          const respondent = await this.getRespondent();
          this.respondent = respondent;
          if (respondent.saq_id) {
            if (respondent.env) {
              api.setEnv(respondent.env);
            }
            this.saq_id = respondent.saq_id;
            if (respondent.language) {
              this.setLocale(respondent.language);
            }
            if (respondent.definition) {
              this.def = respondent.definition;
            } else {
              this.def = await insideApi.getDefinition(this.saq_id);
            }
            if (this.def?.id) {
              if (this.def?.active || this.preview) {
                router.push({
                  name: 'Questionnaire', params: {
                    saq_id: this.saq_id,
                    respondent_id: this.respondent_id,
                    respondent: this.respondent,
                    def: this.def,
                    preview: this.preview,
                    readOnly: this.readOnly,
                    addedContext: this.addedContext,
                  }
                });
              } else {
                this.isExpired = true;
                this.loading = false;
              }
            } else {
              this.isInvalid = true;
              this.loading = false;
            }
          } else {
            this.isInvalid = true;
            this.loading = false;
          }
        } catch (e) {
          this.isInvalid = true;
          this.loading = false;
        }
      }
    }
  }
};
</script>

<template>
  <div
    id="nace-code-selector"
    class="mt-3"
  >
    <v-autocomplete
        v-model="question.answer"
        :items="saqStore.naceCodes"
        :placeholder="$t('placeholder_sector_nace')"
        :prepend-icon="icons.mdiDomain"
        required
        :rules="requiredRule"
        outlined
        item-text="text"
        item-value="value"
        @change="$emit('answer')"
    />
  </div>
</template>

<script>
import {mdiDomain} from '@mdi/js';
import {getSaqStore} from '../stores/saq';

export default {
  setup() {
    const saqStore = getSaqStore();
    return {
      saqStore,
    }
  },
  props: {
    question: Object,
  },
  data: () => ({
    icons: {
      mdiDomain,
    },
  }),
  computed: {
    requiredRule() {
      const required = this.$t('required');
      return [
        v => (!!v || v === 0) || required
      ];
    },
  },
}
</script>

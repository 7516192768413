import Vue from 'vue';
import VueI18n from 'vue-i18n';

import en from 'vuetify/lib/locale/en';
import sv from 'vuetify/lib/locale/sv';
import nb from 'vuetify/lib/locale/no';
import da from 'vuetify/lib/locale/da';
import de from 'vuetify/lib/locale/de';
import fr from 'vuetify/lib/locale/fr';
import fi from 'vuetify/lib/locale/fi';
import pl from 'vuetify/lib/locale/pl';
import it from 'vuetify/lib/locale/it';

Vue.use(VueI18n);

const messages = {
  en: {
    ...require('./locales/en.json'),
    $vuetify: en,
  },
  sv: {
    ...require('./locales/sv.json'),
    $vuetify: sv,
  },
  nb: {
    ...require('./locales/nb.json'),
    $vuetify: nb,
  },
  da: {
    ...require('./locales/da.json'),
    $vuetify: da,
  },
  de: {
    ...require('./locales/de.json'),
    $vuetify: de,
  },
  fr: {
    ...require('./locales/fr.json'),
    $vuetify: fr,
  },
  fi: {
    ...require('./locales/fi.json'),
    $vuetify: fi,
  },
  pl: {
    ...require('./locales/pl.json'),
    $vuetify: pl,
  },
  it: {
    ...require('./locales/it.json'),
    $vuetify: it,
  },
};

const startingLocale = process.env.I18N_LOCALE || 'en';

const i18n = new VueI18n({
  locale: startingLocale,
  fallbackLocale: process.env.I18N_FALLBACK_LOCALE || 'en',
  messages,
});

export default i18n;

<template>
  <v-app id="saq">
    <v-snackbar
        top
        right
        v-model="snackLeft.show"
        :timeout="snackLeft.timeout"
        style="margin-top: 72px;"
    >
      {{ snackLeft.message }}
    </v-snackbar>

    <v-bottom-sheet
        v-model="showSubmitSheet"
        inset
    >
      <v-sheet
          class="text-center"
          height="200px"
      >
        <div class="py-6">
          {{ $t('submit_text') }}
        </div>
        <v-btn
            color="success"
            outlined
            large
            @click="openSubmit"
        >
          {{ $t('button_submit_answers') }}
        </v-btn>
        <div>
          <v-btn
              class="mt-4"
              color="secondary"
              text
              @click="sheet_submit = false"
          >
            {{ $t('button_not_yet') }}
          </v-btn>
        </div>
      </v-sheet>
    </v-bottom-sheet>

    <v-dialog
        v-model="dialog_submit"
        max-width="700px"
    >
      <v-card>
        <v-card-title class="text-h3">
          {{ $t('submit_answers') }}
        </v-card-title>
        <v-card-text>
          <p
              v-if="saqStore.texts.submit_text"
              v-html="saqStore.texts.submit_text"
          />
          <p v-else>
            {{ $t('submit_instruction') }}
          </p>
          <v-form ref="submitform">
            <v-text-field
                v-model="signature"
                :label="$t('full_name')"
                :rules="requiredRule"
                required
                hide-details
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
              color="secondary"
              text
              @click="dialog_submit = false"
          >
            {{ $t('button_not_yet') }}
          </v-btn>
          <v-spacer />
          <v-btn
              color="success"
              outlined
              :disabled="!signature"
              @click="submitQuestionnaire"
          >
            {{
              $t('button_submit_answers')
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="dialog_help"
        max-width="700px"
    >
      <v-card>
        <v-card-title class="text-h3">
          {{ $t('instructions') }}
        </v-card-title>
        <v-card-text>{{ $t('instruction_text') }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
              color="info"
              text
              @click="dialog_help = false"
          >
            {{ $t('button_close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-app-bar
        app
        color="#fff"
        elevation="3"
        clipped-left
        dense
    >
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-app-bar-nav-icon
              :disabled="front"
              @click.stop="drawer = !drawer"
              v-on="on"
          />
        </template>
        <span>{{ $t('button_menu') }}</span>
      </v-tooltip>
      <v-toolbar-title v-text="title" />
      <v-spacer />
      <v-menu
          v-if="isMobile && supportedLanguages.length > 1"
          close-on-content-click
          bottom
          left
          offset-y
      >
        <template #activator="{ on, attrs }">
          <v-btn
              icon
              v-on="on"
              v-bind="attrs"
          >
            <v-icon>{{ icons.mdiTranslate }}</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item-group
              v-model="language"
              @change="changeLanguage"
          >
            <v-list-item
                v-for="lang of supportedLanguages"
                :key="lang.code"
                :value="lang.code"
            >
              <v-list-item-title>{{ lang.nativeName }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <v-select
          v-else-if="supportedLanguages.length > 1"
          :items="supportedLanguages"
          v-model="language"
          item-text="nativeName"
          item-value="code"
          @change="changeLanguage"
          outlined
          dense
          class="mt-6 mr-2"
          style="max-width: 144px"
      />
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn
              icon
              dark
              @click.stop="front = !front"
              v-on="on"
          >
            <v-progress-circular
                color="success"
                :value="respondent.progress_percent || 0"
            >
              <span style="font-size: .45rem; color: #fff; font-weight: 500">{{
                  `${respondent.progress_percent}%`
                }}</span>
            </v-progress-circular>
          </v-btn>
        </template>
        <span>{{ $t('button_show_progress') }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn
              icon
              @click.stop="dialog_help = true"
              v-on="on"
          >
            <v-icon>{{ icons.mdiHelp }}</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('button_instructions') }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn
              icon
              :loading="saving"
              :disabled="!unsaved || preview || readOnly"
              @click.stop="saveProgress(true)"
              v-on="on"
          >
            <v-icon>{{ icons.mdiContentSave }}</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('button_save_progress') }}</span>
      </v-tooltip>
    </v-app-bar>

    <v-navigation-drawer
        v-model="showDrawer"
        :width="width"
        :mobile-breakpoint="960"
        app
        clipped
        :key="refreshKey"
    >
      <v-list dense>
        <v-list-item
            link
            @click="showInstructions = true"
            v-if="instructions.title"
        >
          <v-list-item-content>
            <v-list-item-title :class="{instructionsActive: showInstructions}">
              {{ instructions.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group
            v-for="area of areas"
            :key="area.id"
            v-model="area.active"
            no-action
        >
          <template #activator>
            <v-list-item-title
                v-text="translate(`a:${area.id}`, area.title)"
                :title="translate(`a:${area.id}`, area.title)"
            />
          </template>
          <v-list-item
              v-for="group of area.groups"
              :key="group.id"
              link
              class="pl-5"
              :disabled="!group.questions_total"
              @click="setGroup(area.id, group.id, false)"
          >
            <v-list-item-icon class="mr-2">
              <v-icon
                  v-if="group.done"
                  color="success"
              >
                {{ icons.mdiCheckCircle }}
              </v-icon>
              <v-icon
                  v-if="!group.done"
                  color="secondary"
              >
                {{ icons.mdiCircleOutline }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                  v-text="translate(`g:${group.id}`, group.title)"
                  :title="translate(`g:${group.id}`, group.title)"
              />
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-banner
          v-if="banner_preview"
          single-line
      >
        Preview mode. The progress will not be saved.
        <template #actions>
          <v-btn
              text
              color="info"
              @click="banner_preview = false"
          >
            Dismiss
          </v-btn>
        </template>
      </v-banner>
      <v-container fluid>
        <v-row>
          <v-col>
            <saq-submitted
                v-if="submitted"
                :result="result"
            />
            <div v-else-if="showInstructions && !front">
              <v-row justify="center">
                <v-col cols="10">
                  <h1 class="text-h3 pt-0 pb-3">
                    {{ translate('i:title', instructions.title) }}
                  </h1>
                  <div v-html="translate('i:content', instructions.content)" />
                </v-col>
              </v-row>
              <div class="d-flex justify-end mt-4">
                <v-tooltip
                    right
                >
                  <template #activator="{ on }">
                    <v-btn
                        v-on="on"
                        @click.stop="setGroup(null, null, true)"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="info"
                    >
                      <v-icon>{{ icons.mdiArrowRight }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('continue') }}</span>
                </v-tooltip>
              </div>
            </div>
            <saq-front
                v-if="!submitted && front && contentLoaded"
                :areas="areas"
                :welcomed="welcomed"
                :respondent_tags="respondent.tags"
                :answer_tags="answer_tags"
                :submittable="submittable"
                :logos="logos"
                @closefront="front = false"
                @welcomeshown="welcomed = true"
                @checkdone="checkDone"
                @submit="dialog_submit = true"
                :key="refreshKey"
                @percentage="setPercentage"
            />
            <saq-group
                v-if="!showInstructions && !submitted && !front"
                :areas="areas"
                :groups="groups"
                :area_id="area_id"
                :group_id="group_id"
                :respondent_id="respondent_id"
                :supplier_id="respondent.supplier_id.toString()"
                :respondent_tags="respondent.tags"
                :answer_tags="answer_tags"
                :index-dict="indexDict"
                :company="company"
                :saq_id="saq_id"
                :language="language"
                :key="refreshKey"
                :context="context"
                :submittable="submittable"
                :locked="locked"
                @answer="handleAnswer"
                @checkdone="checkDone"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-snackbar
        v-model="snack.show"
        :timeout="snack.timeout"
    >
      {{ snack.message }}
    </v-snackbar>
  </v-app>
</template>

<script>
/* eslint-disable vue/no-mutating-props */

import api from '../core-api';
import insideApi from '../inside-api';
import {
  mdiArrowLeft,
  mdiArrowRight,
  mdiCheckCircle,
  mdiCircleOutline,
  mdiContentSave,
  mdiHelp,
  mdiHome,
  mdiProgressCheck,
  mdiTranslate,
} from '@mdi/js';
import SaqFront from '../components/SaqFront';
import SaqGroup from '../components/SaqGroup';
import SaqSubmitted from '../components/SaqSubmitted';
import Languages from '../data/Languages.json';
import Translation from '../mixins/translation';
import {getSaqStore} from '../stores/saq.js';
import {isValueFalseOrZero, isValueOrZero} from '../utils';

export default {
  name: 'Saq',
  mixins: [
    Translation,
  ],
  setup() {
    const saqStore = getSaqStore();
    return {
      saqStore,
    };
  },
  mounted() {
    this.loadSaq();
    this.onResize();
    window.addEventListener('resize', this.onResize, {passive: true});
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, {passive: true});
    }
  },
  components: {
    SaqFront,
    SaqGroup,
    SaqSubmitted,
  },
  props: {
    saq_id: String,
    respondent_id: String,
    def: Object,
    respondent: Object,
    preview: Boolean,
    readOnly: Boolean,
    addedContext: {
      type: Object, default: () => {
      },
    },
  },
  data: () => ({
    drawer: true,
    snackLeft: {
      timeout: 2000,
      message: '',
      show: false,
    },
    context: {},
    locked: [],
    instructions: {},
    showInstructions: false,
    refreshKey: 1,
    contentLoaded: false,
    env: '',
    company: '',
    language: 'en',
    languages: Languages,
    logos: {},
    indexDict: {},
    areas: [],
    groups: [],
    answer_tags: [],
    area_id: '',
    group_id: '',
    front: true,
    snack: {
      show: false,
      message: '',
      timeout: 4000,
    },
    isMobile: window.innerWidth < 600,
    banner_preview: false,
    unsaved: false,
    loading: false,
    sheet_submit: false,
    dialog_submit: false,
    signature: '',
    submittable: false,
    submitted: false,
    result: {},
    saving: false,
    autosave: null,
    dialog_help: false,
    welcomed: false,
    width: '270',
    icons: {
      mdiCheckCircle,
      mdiCircleOutline,
      mdiContentSave,
      mdiHome,
      mdiProgressCheck,
      mdiHelp,
      mdiArrowLeft,
      mdiArrowRight,
      mdiTranslate,
    },
  }),
  computed: {
    supportedLanguages() {
      if (Array.isArray(this.def.languages) && this.def.languages.length) {
        let translations = Array.isArray(this.def.translations) ? this.def.translations : [];
        return this.languages.filter(i => this.def.languages.includes(i.code) || this.def.languages.find(e => i.altCodes.includes(e))).map(i => ({
          ...i,
          translation: translations.includes(i.code),
          default: i.code === this.def.lang,
        }));
      }
      return [this.languages.find(i => i.code === this.def?.lang)];
    },
    requiredRule() {
      const required = this.$t('required');
      return [
        v => (!!v || v === 0) || required,
      ];
    },
    emailRule() {
      const required = this.$t('required');
      const must_be_valid = this.$t('must_be_valid');
      return [
        v => !!v || required,
        v => /.+@.+\..+/.test(v) || must_be_valid,
      ];
    },
    showDrawer: {
      get() {
        return this.drawer && !this.front && !this.submitted;
      },
      set(value) {
        this.drawer = value;
      },
    },
    showSubmitSheet: {
      get() {
        return this.sheet_submit && !this.front;
      },
      set(value) {
        this.sheet_submit = value;
      },
    },
    saq_title() {
      return this.def.display_title ? this.translate('dt', this.def.display_title) : this.def.title ? this.translate('t', this.def.title) : this.$t('saq');
    },
    title() {
      if (this.company) {
        return `${this.company} – ${this.saq_title}`;
      }
      return this.saq_title;
    },
  },
  watch: {
    unsaved() {
      if (this.unsaved) {
        window.onbeforeunload = function () {
          return true;
        };
      } else {
        window.onbeforeunload = null;
      }
    },
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600;
    },
    loadSaq() {
      this.env = api.getEnv();
      if (this.saq_id && this.respondent_id && this.def.client_id) {
        insideApi.setSupplierId(this.respondent.supplier_id);
        insideApi.setSaqId(this.respondent.saq_id);
        if (this.preview || this.readOnly) {
          this.banner_preview = true;
        }
        if (this.def.logos) {
          this.logos = this.def.logos;
        }
        if (this.def.company) {
          this.company = this.def.company;
        }
        if (this.def.instructions?.show) {
          this.instructions = this.def.instructions;
          if (this.instructions.content) {
            this.instructions.content = this.instructions.content.replace(/\[CLIENT_COMPANY]/g, this.company);
          }
        }
        if (this.respondent.data) {
          this.areas = this.respondent.data;
        } else {
          this.areas = this.def.areas || this.def.questions;
        }
        if (this.respondent.status) {
          if (this.respondent.status === 'invited') {
            this.respondent.status = 'viewed';
            this.respondent.ts_viewed = new Date();
            this.saveProgress(false).catch(err => {
              console.error(err);
            });
          } else if (this.respondent.status === 'submitted') {
            this.submitted = true;
          }
        }
        if (!this.respondent.tags) {
          this.respondent.tags = [];
        }
        if (this.respondent.welcomed) {
          this.welcomed = true;
        }
        this.context = {
          ...this.respondent.context && {...this.respondent.context},
          ...this.def.context && {...this.def.context},
          ...this.addedContext,
        };
        if (Array.isArray(this.def.locked)) {
          this.locked = this.def.locked;
        }
        if (!this.respondent.answer_tags) {
          this.respondent.answer_tags = {};
        } else {
          for (const id of Object.keys(this.respondent.answer_tags)) {
            let tagging = this.respondent.answer_tags[id];
            if (Array.isArray(tagging)) {
              for (const tag of tagging) {
                this.answer_tags.push(tag);
              }
            } else {
              this.answer_tags.push(tagging);
            }
          }
        }

        // Setting language based on respondent settings and available languages
        const respondentLanguageMatch = this.respondent.language ? this.supportedLanguages.find(i => i.code === this.respondent.language.toLowerCase() || i.altCodes.includes(this.respondent.language.toLowerCase())) : null;
        const defLanguageMatch = this.supportedLanguages.find(i => i.code === this.def.lang.toLowerCase() || i.altCodes.includes(this.def.lang.toLowerCase()));
        if (this.respondent.language && respondentLanguageMatch) {
          this.changeLanguage(respondentLanguageMatch.code);
        } else if (defLanguageMatch) {
          this.changeLanguage(this.language = defLanguageMatch.code);
        } else {
          this.changeLanguage(this.language = this.def.lang);
        }

        insideApi.getIndexDict().then(res => {
          this.indexDict = res;
        }).catch(err => {
          console.error(err);
        });

        this.area_id = this.respondent.area || this.areas[0].id;
        this.group_id = this.respondent.group || this.areas[0].groups[0].id;
        if (this.instructions.title) {
          this.showInstructions = true;
        } else {
          this.setGroup(this.area_id, this.group_id, true);
        }
        this.respondent.area = this.area_id;
        this.respondent.group = this.group_id;
        this.checkDone();
        this.startAutoSave();
      } else {
        window.location.href = '/';
      }
    },
    setLocale(lang) {
      if (lang === 'no' || lang === 'nn') { // Norwegian variants, only nb is supported in i18n
        lang = 'nb';
      }
      this.$i18n.locale = lang;
      const html = document.documentElement;
      const metaContentLanguage = document.getElementById('metaContentLanguage');
      html.setAttribute('lang', lang);
      metaContentLanguage.setAttribute('content', lang);
    },
    getLanguageVariants(lang) {
      const langItem = this.supportedLanguages.find(i => i.code === lang.toLowerCase() || (Array.isArray(i.altCodes) && i.altCodes.includes(lang.toLowerCase())));
      let matches;
      if (langItem) {
        matches = [
          langItem.code,
          ...Array.isArray(langItem.altCodes) && langItem.altCodes,
        ];
      } else {
        matches = [lang];
      }
      const saqLanguages = Array.isArray(this.def.translations) ? [this.def.lang, ...this.def.translations] : [this.def.lang];
      const result = matches.filter(i => saqLanguages.includes(i));
      return result;
    },
    changeLanguage(lang) {
      const translationLang = this.parseTranslationLang(lang);
      const promises = [
        insideApi.getTexts(this.saq_id, this.parseTextsLang(lang)),
        insideApi.getNaceCodes(lang),
        insideApi.getCertificateTypes(lang),
      ];
      if (lang !== this.def.lang && translationLang) {
        promises.push(insideApi.getContentTranslation(this.saq_id, translationLang));
      }
      Promise.all(promises).then(res => {
        const [texts = {}, naceCodes, certificateTypes, translation = {}] = res;
        this.saqStore.texts = texts;
        this.saqStore.naceCodes = naceCodes;
        this.saqStore.translation = translation;
        if (Array.isArray(certificateTypes) && certificateTypes.length > 0 && typeof certificateTypes[0] === 'string') {
          this.saqStore.certificateTypes = certificateTypes;
        } else if (Array.isArray(certificateTypes)) {
          this.saqStore.certificateTypes = certificateTypes.map(i => i.text).filter(i => !!i);
        }
        this.setLocale(lang);
        this.language = lang;
        if (!texts.welcome_text) {
          texts.welcome_text = this.$t('welcome_text');
        }
        this.contentLoaded = true;
        this.refreshKey++;
        if (Object.keys(translation).length === 0 && !this.getLanguageVariants(lang).length) {
          this.snackLeft.message = this.$t('error_texts.unsupported_language');
          this.snackLeft.show = true;
        }
      }).catch(err => {
        console.error(err);
      });
    },
    parseTextsLang(lang) {
      if (Array.isArray(this.def?.languages) && this.def.languages.includes(lang)) {
        return lang;
      } else if (Array.isArray(this.def?.languages) && (lang === 'no' || lang === 'nb' || lang === 'nn')) {
        return this.def.languages.find(i => i === 'no' || i === 'nb' || i === 'nn') || '';
      }
      return this.def.lang;
    },
    parseTranslationLang(lang) {
      if (Array.isArray(this.def?.translations) && this.def.translations.includes(lang)) {
        return lang;
      } else if (Array.isArray(this.def?.translations) && (lang === 'no' || lang === 'nb' || lang === 'nn')) {
        return this.def.translations.find(i => i === 'no' || i === 'nb' || i === 'nn') || '';
      }
      return '';
    },
    refreshGroups() {
      this.groups = [];
      for (const area of this.areas) {
        for (const group of area.groups) {
          if (group.questions_total) {
            // Has included questions
            this.groups.push({
              area_id: area.id,
              area_title: area.title,
              group_id: group.id,
              group_title: group.title,
            });
          }
        }
      }
    },
    tagFilter(question) {
      const matchTags = [].concat(this.respondent.tags, this.answer_tags);
      let show = true;
      let matchedTags = [];
      if (question.inclusion_tags && Array.isArray(question.inclusion_tags) && question.inclusion_tags.length) {
        question.included_by_tag = false;
        show = false;
        question.inclusion_tags.forEach(tag => {
          if (matchTags.indexOf(tag) > -1) {
            show = true;
            question.included_by_tag = true;
            matchedTags.push(tag);
          }
        });
      }
      if (question.exclusion_tags && Array.isArray(question.exclusion_tags) && question.exclusion_tags.length) {
        question.excluded_by_tag = false;
        question.exclusion_tags.forEach(tag => {
          if (matchTags.indexOf(tag) > -1) {
            show = false;
            question.excluded_by_tag = true;
            matchedTags.push(tag);
          }
        });
      }
      if (question.inclusion_tags || question.exclusion_tags) {
        question.matched_tags = matchedTags;
      }
      return show;
    },
    startAutoSave() {
      this.autosave = setInterval(() => {
        if (this.unsaved) {
          this.saveProgress(true);
        }
      }, 60000);
    },
    handleAnswer(triggerSave, id, tags) {
      tags = Array.isArray(tags) ? [...new Set(tags)] : [];
      this.respondent.answer_tags[id] = tags;
      this.answer_tags = [];
      for (const id of Object.keys(this.respondent.answer_tags)) {
        let tagging = this.respondent.answer_tags[id];
        if (Array.isArray(tagging)) {
          for (const tag of tagging) {
            this.answer_tags.push(tag);
          }
        } else {
          this.answer_tags.push(tagging);
        }
      }
      this.unsaved = true;
      if (triggerSave) {
        this.saveProgress(false);
      }
      this.checkDone();
    },
    openSubmit() {
      if (this.submittable) {
        this.sheet_submit = false;
        this.dialog_submit = true;
      }
    },
    setPercentage(value) {
      this.respondent.progress_percent = value;
    },
    checkDone() {
      try {
        let qt = 0, qa = 0;
        const arrtype = ['mc', 'countries', 'unittable', 'files', 'sectors', 'companytable', 'certificatetable', 'principaltable', 'ownertable', 'customtable', 'suppliers', 'listcount'];
        for (const area of this.areas) {
          for (const group of area.groups) {
            let gt = 0, ga = 0;
            for (const question of group.questions) {
              if (question.type === 'slider') {
                console.log('slider', question.answer);
              }
              const isQuestionArrType = arrtype.includes(question.type) || (question.type === 'select' && !!question.multiselect);
              if (this.tagFilter(question)) {
                qt += 1;
                gt += 1;
                if (isValueFalseOrZero(question.answer) || question.skipped) {
                  if (question.skipped || !isQuestionArrType || (isQuestionArrType && Array.isArray(question.answer) && question.answer.length)) {
                    qa += 1;
                    ga += 1;
                  }
                }
                if (question.subquestions && question.subs_required) {
                  for (const subquestion of question.subquestions) {
                    const isSubQuestionArrType = arrtype.includes(subquestion.type) || (subquestion.type === 'select' && !!subquestion.multiselect);
                    if (this.tagFilter(subquestion)) {
                      qt += 1;
                      gt += 1;
                      if (isValueFalseOrZero(subquestion.answer) || subquestion.skipped) {
                        if (subquestion.skipped || !isSubQuestionArrType || (isSubQuestionArrType && Array.isArray(subquestion.answer) && subquestion.answer.length)) {
                          qa += 1;
                          ga += 1;
                        }
                      }
                    }
                  }
                }
              }
            }
            group.questions_total = gt;
            group.questions_answered = ga;
            group.done = (gt === ga);
          }
        }
        this.refreshGroups();
        if (qa === qt) {
          this.submittable = true;
          setTimeout(() => {
            if (this.submittable) {
              this.sheet_submit = (this.submittable && this.respondent.status !== 'submitted');
            }
          }, 1000);
        } else {
          this.submittable = false;
          this.sheet_submit = false;
        }
        this.respondent.progress_percent = (qt > 0) ? Math.floor((qa / qt) * 100) : 0;
      } catch {
        // Not handled intentionally
      }
    },
    setGroup(area, group, forceOpen) {
      this.showInstructions = false;
      if (area) {
        this.area_id = area;
      }
      if (group) {
        this.group_id = group;
      }
      this.respondent.area = area;
      this.respondent.group = group;
      if (forceOpen) {
        let _area = this.areas.find(a => a.id === area);
        if (_area) {
          _area.active = true;
        }
      }
      this.scrollToTop();
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    },
    async saveProgress(show) {
      if (!this.preview && !this.readOnly) {
        this.saving = true;
        if (!this.respondent.ts_viewed) {
          this.respondent.ts_viewed = new Date();
        }
        if (!this.respondent.ts_started) {
          this.respondent.status = 'started';
          this.respondent.ts_started = new Date();
        }
        this.respondent.welcomed = this.welcomed;
        this.respondent.active_language = this.language;
        const result = await insideApi.saveRespondent(this.respondent_id, this.respondent, this.areas);
        if (!result.success) {
          this.snack.message = this.$t('messages.unable_to_save');
          this.snack.show = true;
        } else if (show) {
          this.snack.message = this.$t('messages.progress_saved');
          this.snack.show = true;
          this.unsaved = false;
        }
        this.saving = false;
      }
    },
    submitQuestionnaire() {
      this.saving = true;
      this.$refs.submitform.validate();
      this.respondent.active_language = this.language;
      this.respondent.answer_tags = this.answer_tags;
      this.respondent.context = this.context;
      this.respondent.content = {
        yes: this.$t('yes'),
        no: this.$t('no'),
      };
      if (this.submittable && this.signature) {
        this.respondent.signature = this.signature;
        this.dialog_submit = false;
        if (!this.preview && !this.readOnly) {
          insideApi.submitQuestionnaire(this.respondent, this.areas).then(res => {
            if (!res.success) {
              this.snack.message = this.$t('messages.unable_to_submit');
              this.snack.show = true;
            } else {
              this.result = res.result;
              this.unsaved = false;
              this.submitted = true;
            }
          }).catch(err => {
            console.error(err);
          }).finally(() => {
            this.saving = false;
          });
        } else {
          this.unsaved = false;
          this.respondent.status = 'submitted';
          this.respondent.ts_submitted = new Date();
          this.submitted = true;
          this.saving = false;
        }
      }
    },
  },
};
</script>

<style>
.instructionsActive {
  color: #183d72;
}
</style>

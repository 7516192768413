<template>
  <div id="saq_submitted">
    <v-row justify="center">
      <v-col cols="10">
        <h1 class="text-h2 my-4">
{{ $t('answers_submitted') }}
</h1>
        <p v-html="saqStore.texts.thankyou_text || $t('submitted_text')" />
        <p>{{ $t('may_close') }}</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {getSaqStore} from '../stores/saq';

export default {
  setup() {
    const saqStore = getSaqStore();
    return {
      saqStore,
    }
  },
  props: {
    results: Object,
  },
  mounted() {
  },
}
</script>

<template>
  <v-input
      v-model="matrix"
      :rules="tableRule"
  >
    <v-snackbar
        v-model="snack"
        :timeout="timeout"
    >
      {{ snackMessage }}
    </v-snackbar>

    <v-dialog
        v-model="dialogMap"
        max-width="700"
    >
      <v-card>
        <v-card-text class="pt-4">
          <GmapMap
              :center="location"
              :zoom="zoom"
              :options="{
              zoomControl: true,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
              disableDefaultUi: true,
              draggableCursor: 'crosshair',
            }"
              map-type-id="roadmap"
              class="map"
              ref="gmap"
          >
            <GmapMarker
                :position="markerLocation"
                :clickable="true"
                :draggable="true"
            />
          </GmapMap>
          <v-row class="mt-4">
            <v-col cols="6">
              <v-text-field
                  type="number"
                  :value="markerLocation.lat"
                  :label="$t('latitude')"
                  hide-details
                  dense
                  @change="val => markerLocation.lat = Number(val)"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                  type="number"
                  :value="markerLocation.lng"
                  :label="$t('longitude')"
                  hide-details
                  dense
                  @change="val => markerLocation.lng = Number(val)"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
              color="secondary"
              text
              @click="dialogMap = false"
          >
            {{ $t('button_cancel') }}
          </v-btn>
          <v-spacer />
          <v-btn
              color="info"
              text
              @click="saveMapMarker"
          >
            {{ $t('button_ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="dialog"
        max-width="800"
        scrollable
    >
      <v-card>
        <v-card-title>{{ formTitle }}</v-card-title>
        <v-card-text>
          <v-form
              ref="itemtable"
              v-model="validItem"
          >
            <p
                v-if="question.item_instructions"
                v-html="translate(`${question.id}:ii`, question.item_instructions)"
            />
            <v-row no-gutters>
              <template v-for="column of columns">
                <v-col
                    :key="`${column.value}-DB`"
                    v-if="checkQualifier(column) && !!column.dividerBefore"
                    cols="12"
                >
                  <v-divider class="mt-2 mb-6" />
                </v-col>
                <v-col
                    :key="`${column.value}-1`"
                    cols="12"
                    :sm="(column.fullWidth || question.max_columns === 1) ? 12 : 6"
                    :md="(column.fullWidth || question.max_columns === 1) ? 12 : question.max_columns === 2 ? 6 : 4"
                    v-if="checkQualifier(column)"
                    class="mb-4 px-md-2"
                >
                  <div
                      v-if="column.prependLabel"
                      class="mb-1"
                  >
                    <label>{{ column.required ? `${column.label} *` : column.label }}</label>
                  </div>
                  <v-checkbox
                      v-if="column.type === 'checkbox'"
                      v-model="editedItem[column.value]"
                      :label="column.prependLabel ? undefined : column.required ? `${column.label} *` : column.label"
                      :hint="column.hint"
                      :persistent-hint="!!column.hint"
                      hide-details="auto"
                      :dense="!!column.dense"
                  />
                  <v-radio-group
                      v-else-if="column.type === 'yesno'"
                      v-model="editedItem[column.value]"
                      :label="column.prependLabel ? undefined : column.required ? `${column.label} *` : column.label"
                      :hint="column.hint"
                      :persistent-hint="!!column.hint"
                      hide-details="auto"
                      :row="column.row"
                      :dense="!!column.dense"
                  >
                    <v-radio
                        :value="true"
                        :label="$t('yes')"
                    />
                    <v-radio
                        :value="false"
                        :label="$t('no')"
                    />
                  </v-radio-group>
                  <v-text-field
                      v-else-if="column.type === 'mapmarker'"
                      readonly
                      v-model="editedItem[column.value]"
                      :label="column.prependLabel ? undefined : column.required ? `${column.label} *` : column.label"
                      :hint="column.hint"
                      :persistent-hint="!!column.hint"
                      :rules="column.required ? requiredRule : [true]"
                      :append-icon="icons.mdiMapMarker"
                      @click="openMap(column.value)"
                      hide-details="auto"
                      outlined
                      :dense="!!column.dense"
                  />
                  <v-select
                      v-else-if="column.type === 'select' && column.options.length"
                      :items="column.options"
                      v-model="editedItem[column.value]"
                      :label="column.prependLabel ? undefined : column.required ? `${column.label} *` : column.label"
                      :hint="column.hint"
                      :persistent-hint="!!column.hint"
                      :multiple="!!column.multiple"
                      :rules="column.required ? requiredRule : [true]"
                      hide-details="auto"
                      outlined
                      :dense="!!column.dense"
                  />
                  <v-text-field
                      v-else-if="column.type === 'number'"
                      type="number"
                      v-model="editedItem[column.value]"
                      :append-icon="column.percent ? icons.mdiPercent : null"
                      :label="column.prependLabel ? undefined : column.required ? `${column.label} *` : column.label"
                      :hint="column.hint"
                      :persistent-hint="!!column.hint"
                      :rules="column.required ? requiredRule : [true]"
                      hide-details="auto"
                      outlined
                      :dense="!!column.dense"
                  />
                  <div v-else-if="column.type === 'country'">
                    <v-autocomplete
                        v-if="column.multiple"
                        :items="localeCountries"
                        item-text="name"
                        item-value="code"
                        :label="column.prependLabel ? undefined : column.required ? `${column.label} *` : column.label"
                        :append-icon="icons.mdiEarth"
                        multiple
                        outlined
                        chips
                        small-chips
                        deletable-chips
                        :dense="!!column.dense"
                        v-model="editedItem[column.value]"
                        :hint="column.hint"
                        :persistent-hint="!!column.hint"
                        :rules="column.required ? requiredRule : [true]"
                    />
                    <v-autocomplete
                        v-if="!column.multiple"
                        :items="localeCountries"
                        item-text="name"
                        item-value="code"
                        :label="column.prependLabel ? undefined : column.required ? `${column.label} *` : column.label"
                        :append-icon="icons.mdiEarth"
                        outlined
                        :dense="!!column.dense"
                        v-model="editedItem[column.value]"
                        :hint="column.hint"
                        :persistent-hint="!!column.hint"
                        :rules="column.required ? requiredRule : [true]"
                    />
                  </div>
                  <div v-else-if="column.type === 'file'">
                    <div
                        v-if="editedItem.file && editedItem.file.id"
                        class="d-flex"
                    >
                      <v-icon style="margin-right: 9px;">
                        {{ icons.mdiPaperclip }}
                      </v-icon>
                      <v-chip
                          color="info"
                          dark
                          @click="downloadFile(editedItem.file.id, editedItem.file.original)"
                      >
                        {{ editedItem.file.original }}
                      </v-chip>
                    </div>
                    <v-file-input
                        v-else
                        v-model="uploads[column.prop]"
                        :loading="uploading"
                        show-size
                        :placeholder="$t('placeholder_file')"
                        :label="column.prependLabel ? undefined : column.required ? `${column.label} *` : column.label"
                        hide-details="auto"
                        outlined
                        :dense="!!column.dense"
                    >
                      <template #progress>
                        <v-progress-linear :value="progress" />
                      </template>
                    </v-file-input>
                  </div>
                  <v-text-field
                      v-else-if="column.type"
                      v-model="editedItem[column.value]"
                      :label="column.prependLabel ? undefined : column.required ? `${column.label} *` : column.label"
                      :hint="column.hint"
                      :persistent-hint="!!column.hint"
                      :rules="column.required ? requiredRule : [true]"
                      hide-details="auto"
                      outlined
                      :dense="!!column.dense"
                  />
                </v-col>
                <v-col
                    :key="`${column.value}-B`"
                    v-if="checkQualifier(column) && !!column.breakAfter && !column.fullWidth"
                />
                <v-col
                    :key="`${column.value}-DA`"
                    v-if="!!column.dividerAfter || !!column.dividerAfter"
                    cols="12"
                >
                  <v-divider class="mt-2 mb-6" />
                </v-col>
              </template>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
              color="secondary"
              text
              @click="close"
          >
            {{ $t('button_cancel') }}
          </v-btn>
          <v-spacer />
          <v-btn
              color="info"
              text
              :disabled="!validItem"
              @click="save"
          >
            {{ $t('button_save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
        :headers="columns.filter(i => i.header === true)"
        :items="matrix"
        :no-data-text="$t('no_rows_added')"
        disable-sort
        disable-pagination
        disable-filtering
        hide-default-footer
        dense
        class="mt-3 fullwidth"
    >
      <template #footer>
        <div class="d-flex justify-end">
          <v-btn
              fab
              small
              color="info"
              dark
              @click="addItem"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
          </v-btn>
        </div>
      </template>
      <template #item.country="{ item }">
        {{ parseCountries(item.country) }}
      </template>
      <template #item.countries="{ item }">
        {{ parseCountries(item.countries) }}
      </template>
      <template #item.file="{ item }">
        <v-chip
            v-if="item.file && item.file.id"
            small
            color="info"
            dark
            @click="downloadFile(item.file.id, item.file.original)"
        >
          {{ item.file.original }}
        </v-chip>
      </template>
      <template #item.actions="{ item }">
        <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
        >
          {{ icons.mdiPencil }}
        </v-icon>
        <v-icon
            small
            @click="deleteItem(item)"
        >
          {{ icons.mdiDelete }}
        </v-icon>
      </template>
    </v-data-table>
  </v-input>
</template>

<script>
import {mdiPencil, mdiDelete, mdiPlus, mdiMapMarker, mdiPercent, mdiPaperclip, mdiEarth} from '@mdi/js';
import Translation from '../mixins/translation';
import api from '../core-api';
import Countries from '../data/Countries.json';

export default {
  name: 'CustomTable',
  props: {
    question: Object,
    respondent_id: String,
    supplier_id: String,
    saq_id: String,
  },
  mixins: [Translation],
  data: () => ({
    dialog: false,
    dialogMap: false,
    matrix: [],
    editedIndex: -1,
    editedItem: {},
    mapProp: null,
    validItem: undefined,
    uploads: {},
    uploading: false,
    localeCountries: [],
    progress: null,
    timeout: 4000,
    snack: false,
    snackMessage: '',
    zoom: 1,
    icons: {
      mdiPencil,
      mdiDelete,
      mdiPlus,
      mdiMapMarker,
      mdiPercent,
      mdiPaperclip,
      mdiEarth
    },
    location: {
      lat: 53,
      lng: 9,
    },
    markerSet: false,
    markerLocation: {
      lat: 53,
      lng: 9,
    },
  }),
  computed: {
    requiredRule() {
      const required = this.$t('required');
      return [
        v => (!!v || v === 0) || required,
      ];
    },
    tableRule() {
      const required = this.$t('required');
      return [
        v => !!v.length || required,
      ];
    },
    columns() {
      const items = [];
      if (Array.isArray(this.question.columns)) {
        for (const column of this.question.columns) {
          if (column.prop) {
            items.push({
              label: column.key ? this.$t(column.key) : column.label ? column.label : column.labels ? column.labels[this.$i18n.locale] ?? this.$t(`custom_types.${column.type}`) : this.$t(`custom_types.${column.type}`),
              text: column.headerLabel ? column.headerLabel : column.key ? this.$t(column.key) : column.label ? column.label : column.labels ? column.labels[this.$i18n.locale] ?? this.$t(`custom_types.${column.type}`) : this.$t(`custom_types.${column.type}`),
              value: column.prop,
              prop: column.prop,
              type: column.type ?? 'text',
              ...column.hint && {hint: column.hint},
              options: Array.isArray(column.options) ? column.options.map(i => ({
                text: typeof i === 'object' ? i[this.$i18n.locale] ?? i.text : i,
                value: typeof i === 'object' ? i.value : i,
              })) : [],
              percent: column.percent,
              required: column.required,
              fullWidth: column.fullWidth,
              ...column.qualifier && {qualifier: column.qualifier},
              ...column.qualifierValue && {qualifierValue: column.qualifierValue},
              header: column.header ?? true,
              multiple: column.multiple,
              dense: column.dense,
              breakAfter: column.breakAfter,
              dividerAfter: column.dividerAfter,
              dividerBefore: column.dividerBefore,
              prependLabel: column.prependLabel,
              ...column.row && {row: true},
            });
          }
        }
      }
      items.push({text: this.$t('actions'), value: 'actions', header: true});
      return items;
    },
    formTitle() {
      return this.editedIndex === -1 ? this.$t('add') : this.$t('edit');
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogMap(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.gmap.$mapPromise.then(map => {
            if (!this.markerSet) {
              this.getCurrentPosition().then(position => {
                if (position?.coords) {
                  this.location.lat = position.coords.latitude;
                  this.location.lng = position.coords.longitude;
                  this.markerLocation = this.location;
                  this.zoom = 3;
                }
              }).catch(err => {
                console.log(err);
              });
            }
            map.addListener('click', e => {
              this.markerSet = true;
              this.markerLocation = {lat: parseFloat(e.latLng.lat()), lng: parseFloat(e.latLng.lng())};
              if (this.zoom < 16) {
                this.zoom += 1;
              }
              map.panTo(this.markerLocation);
            });
            map.addListener('zoom_changed', e => {
              this.zoom = map.getZoom();
            });
          });
        });
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.matrix = (Array.isArray(this.question.answer)) ? this.question.answer : [];
      if (!this.question.uploads) {
        this.question.uploads = [];
      }
      this.localeCountries = Countries.map(i => ({
        name: this.parseCountryName(i),
        code: i.alpha3Code,
      })).sort((a, b) => a.name.localeCompare(b.name));
    },
    async downloadFile(id, original) {
      const url = await api.getSignedUrl(id, original);
      if (url) {
        window.location.href = url;
      }
    },
    checkQualifier(column) {
      if (!column.qualifier) return true;
      const qualifier = this.columns.find(i => i.value === column.qualifier);
      if (!qualifier) return true;
      if (['checkbox', 'yesno'].includes(qualifier.type)) {
        return !!this.editedItem[qualifier.value];
      }
      if (column.qualifierValue && typeof this.editedItem[qualifier.value] === 'string') {
        const comparers = column.qualifierValue.split('|');
        return comparers.some(comparer => comparer === this.editedItem[qualifier.value]);
      }
      return false;
    },
    parseCountries(v) {
      let parsed = '';
      if (Array.isArray(v)) {
        parsed = v.map(i => this.$t(`countryNames.${i.toLowerCase()}`)).join(', ');
      } else if (v) {
        parsed = this.$t(`countryNames.${v.toLowerCase()}`);
      }
      return parsed;
    },
    parseCountryName(v) {
      return this.$t(`countryNames.${v.alpha3Code.toLowerCase()}`);
    },
    uploadProgress(progress) {
      this.progress = progress;
    },
    resetValidation() {
      this.$nextTick(() => {
        this.$refs.itemtable.resetValidation();
      });
    },
    addItem() {
      this.editedItem = {};
      this.dialog = true;
      this.resetValidation();
    },
    editItem(item) {
      this.editedIndex = this.matrix.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      this.resetValidation();
    },
    performDeleteItem(item) {
      let upload = this.question.uploads.find(f => f.id === item.file?.id);
      if (item.file?.id && upload) {
        api.deleteFile(item.file.id, upload.original).then(result => {
          if (!result.success) {
            // Not handled
          }
        }).catch(err => {
          console.error('deleteFile', err);
        }).finally(() => {
          this.question.uploads.splice(this.question.uploads.indexOf(upload), 1);
        });
      }
      const index = this.matrix.indexOf(item);
      this.matrix.splice(index, 1);
      this.$emit('answer');
    },
    deleteItem(item) {
      confirm(this.$t('confirm_delete_row')) && this.performDeleteItem(item);
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = {};
        this.editedIndex = -1;
      });
    },
    save() {
      const uploadValues = Object.values(this.uploads);
      if (uploadValues.length > 0 && uploadValues.every(i => !!i)) {
        this.uploading = true;
        const uploads = [];
        for (const prop of Object.keys(this.uploads)) {
          if (this.uploads[prop]) {
            uploads.push({prop, file: this.uploads[prop]});
          }
        }
        api.storeFiles(uploads.map(i => i.file), this.respondent_id, this.saq_id, this.supplier_id, this.uploadProgress).then(res => {
          // Handle legacy situations where "file" is used as the default propety for Custom Table files
          if (res[0].success) {
            this.editedItem.file = {
              id: res[0].id,
              original: res[0].original,
            };
          }
          for (let idx = 0; idx < res.length; idx++) {
            if (res[idx].success) {
              this.editedItem[uploads[idx].prop] = {
                id: res[idx].id,
                original: res[idx].original,
              };
              this.question.uploads.push({
                id: res[idx].id,
                original: res[idx].original,
              });
            } else {
              this.snackMessage = this.$t('notification_upload_failure');
              this.snack = true;
            }
          }
          this.updateMatrix();
        }).catch(() => {
          this.snackMessage = this.$t('notification_upload_failure');
          this.snack = true;
        }).finally(() => {
          this.uploading = false;
          this.uploads = {};
        });
      } else if (this.editedItem.file) {
        this.updateMatrix();
      } else {
        this.updateMatrix();
      }
    },
    updateMatrix() {
      if (this.editedIndex > -1) {
        Object.assign(this.matrix[this.editedIndex], this.editedItem);
      } else {
        this.matrix.push(this.editedItem);
      }
      this.question.answer = this.matrix;
      this.$emit('answer');
      this.close();
    },
    openMap(prop) {
      this.markerSet = false;
      if (this.editedItem[prop]) {
        const coord = this.editedItem[prop].split(',').map(i => parseFloat(i.trim()));
        if (coord.length === 2 && !isNaN(coord[0]) && !isNaN(coord[1])) {
          this.location = {
            lat: coord[0],
            lng: coord[1],
          };
          this.markerLocation = this.location;
          this.zoom = 14;
          this.markerSet = true;
        }
      }
      this.mapProp = prop;
      this.dialogMap = true;
    },
    saveMapMarker() {
      if (this.markerSet || (this.markerLocation.lat && this.markerLocation.lng)) {
        this.$set(this.editedItem, this.mapProp, `${this.markerLocation.lat},${this.markerLocation.lng}`);
      }
      this.dialogMap = false;
    },
    getCurrentPosition() {
      return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        } else {
          resolve(false);
        }
      });
    },
  },
};
</script>

<style scoped>

.map {
  width: 100%;
  height: 50vh;
  background: #f7f8f9;
}

.fullwidth {
  width: 100%;
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Start from '../views/Start.vue'
import Questionnaire from '../views/Saq.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: '/questionnaire',
    name: 'Questionnaire',
    component: Questionnaire,
    props: true
  },
  {
    path: '/:id',
    name: 'Start',
    component: Start
  },
  {
    path: '/',
    name: 'SignIn',
    component: Start
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router

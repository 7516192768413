<template>
  <v-input
    :rules="listRule"
    v-model="question.answer"
    class="mt-4"
  >
    <div class="fullwidth">
      <v-row
        no-gutter
        v-for="(option, idx) of question.options"
        :key="idx"
      >
        <v-col
          cols="6"
          sm="3"
        >
          {{ getOptionText(option) }}
        </v-col>
        <v-col
          cols="6"
          sm="9"
        >
          <v-text-field
            class="pa-0 ma-0"
            type="number"
            style="max-width: 100px"
            v-model="question.answer[idx]"
            hide-details
            dense
          >
            <template
              #append
              v-if="question.percent"
            >
              <div>
              <v-icon
                  small
                  class="pt-2"
              >
                {{ icons.mdiPercent }}
              </v-icon>
              </div>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </div>
  </v-input>
</template>

<script>
import {mdiPercent} from '@mdi/js';

export default {
  name: 'ListCount',
  props: {
    question: Object,
  },
  data: () => ({
    icons:{
      mdiPercent,
    }
  }),
  computed: {
    listRule() {
      const required = this.$t('required');
      if (Array.isArray(this.question.answer)) {
        return [
            v => v.some(i => (!!i || i === 0) || required)
        ];
      } else {
        return [];
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    getOptionText(option) {
      return typeof option === 'object' ? option[this.$i18n.locale] : option;
    },
    initialize() {
      if (!Array.isArray(this.question.answer)) {
        this.question.answer = [];
      }
      if (!Array.isArray(this.question.options)) {
        this.question.options = [];
      }
    },
  },
}
</script>

<style scoped>
.fullwidth {
  width: 100%;
}
</style>

import {getSaqStore} from '../stores/saq';
import { storeToRefs } from 'pinia';

export default {
    setup() {
        const saqStore = getSaqStore();
        return {
            saqStore,
        }
    },
    methods: {
        translate(key, fallback) {
            return this.saqStore.getTranslation(key) ?? fallback;
        },
    }
}

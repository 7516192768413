<template>
  <div id="saq_group">
    <v-dialog
        v-model="dialog_not_completed"
        max-width="400px"
    >
      <v-card>
        <v-card-title>{{ $t('not_completed') }}</v-card-title>
        <v-card-text>{{ $t('not_completed_text') }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
              color="info"
              text
              @click="dialog_not_completed = false"
          >
            {{ $t('button_no') }}
          </v-btn>
          <v-btn
              color="secondary"
              text
              @click="setGroup(target_area_id, target_group_id, false)"
          >
            {{
              $t('button_yes')
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col>
        <v-breadcrumbs
            :items="path"
            class="py-4"
        />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="10">
        <h1 class="text-h3 pt-0 pb-3">
          {{ group_title }}
        </h1>
        <v-form
            ref="groupform"
            v-model="isValid"
        >
          <div
              class="question_container"
              v-for="question in group.questions"
              :key="question.id"
          >
            <saq-question
                v-if="tagFilter(question)"
                :question="question"
                :subquestion="false"
                :respondent_id="respondent_id"
                :supplier_id="supplier_id"
                :saq_id="saq_id"
                :index-dict="indexDict"
                :company="company"
                @answer="handleAnswer"
                @notify="uploadNotification"
                :key="$vnode.key"
                :context="context"
                :locked="locked"
            />
            <transition name="slide-fade">
              <div v-if="Array.isArray(question?.subquestions) && question.subquestions.length > 0 && (subs_triggers.includes(question.id) || question.subs_required) || question.type === 'number'">
                <div
                    class="subquestion_container"
                    v-for="subquestion of question.subquestions"
                    :key="subquestion.id"
                >
                  <saq-question
                      v-if="tagFilter(subquestion)"
                      :question="subquestion"
                      :subquestion="true"
                      :respondent_id="respondent_id"
                      :supplier_id="supplier_id"
                      :saq_id="saq_id"
                      :index-dict="indexDict"
                      :company="company"
                      @answer="handleAnswer"
                      @notify="uploadNotification"
                      :key="$vnode.key"
                      :context="context"
                      :locked="locked"
                  />
                </div>
              </div>
            </transition>
          </div>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-space-between my-3">
        <div>
          <v-tooltip
              v-if="prev_group_id"
              right
          >
            <template #activator="{ on }">
              <v-btn
                  v-on="on"
                  @click.stop="setGroup(prev_area_id, prev_group_id, true)"
                  class="mx-2"
                  fab
                  dark
                  small
                  color="info"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
              </v-btn>
            </template>
            <span>{{ prev_group_title }}</span>
          </v-tooltip>
        </div>
        <div>
          <v-tooltip
              v-if="next_group_id"
              left
          >
            <template #activator="{ on }">
              <v-btn
                  v-on="on"
                  @click.stop="setGroup(next_area_id, next_group_id, true)"
                  class="mx-2"
                  fab
                  dark
                  small
                  color="info"
              >
                <v-icon>{{ icons.mdiArrowRight }}</v-icon>
              </v-btn>
            </template>
            <span>{{ next_group_title }}</span>
          </v-tooltip>
          <div
              v-else-if="!submittable && group.done"
              class="d-flex align-center"
          >
            <v-icon
                x-large
                color="info"
            >
              {{ icons.mdiInformation }}
            </v-icon>
            <div
                class="ml-2"
                style="max-width: 50vw;"
            >
              {{ $t('end_of_questionnaire_check_menu_message') }}
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
    >
      {{ snackbarMessage }}
    </v-snackbar>
  </div>
</template>

<style>
.v-input--selection-controls {
  margin-top: 4px;
}

.slide-fade-enter-active {
  transition: all .5s ease;
}

.slide-fade-leave-active {
  transition: all .5s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateY(50px);
  opacity: 0;
}
</style>

<script>
import {
  mdiCircleOutline,
  mdiCheckCircle,
  mdiContentSave,
  mdiHelp,
  mdiArrowLeft,
  mdiArrowRight,
  mdiInformation
} from '@mdi/js';
import SaqQuestion from '@/components/SaqQuestion.vue';
import Translation from '@/mixins/translation';

export default {
  mixins: [
    Translation
  ],
  props: {
    areas: Array,
    groups: Array,
    area_id: String,
    group_id: String,
    respondent_id: String,
    supplier_id: String,
    respondent_tags: Array,
    answer_tags: Array,
    saq_id: String,
    indexDict: Object,
    company: String,
    language: String,
    submittable: Boolean,
    context: {
      type: Object, default: () => {
      }
    },
    locked: {type: Array, default: () => []},
  },
  components: {
    SaqQuestion,
  },
  watch: {
    group_id() {
      this.curr_area_id = this.area_id;
      this.curr_group_id = this.group_id;
      this.refreshGroup(true);
    },
    language() {
      this.refreshGroup(false);
    }
  },
  created() {
    this.curr_area_id = this.area_id;
    this.curr_group_id = this.group_id;
    this.refreshGroup(true);
  },
  data: () => ({
    path: [],
    group: {},
    subs_triggers: [],
    area_title: '',
    group_title: '',
    curr_area_id: '',
    curr_group_id: '',
    prev_group_title: '',
    prev_group_id: '',
    prev_area_id: '',
    next_group_title: '',
    next_group_id: '',
    next_area_id: '',
    target_area_id: '',
    target_group_id: '',
    dialog_not_completed: false,
    isValid: false,
    timeout: 3000,
    snackbar: false,
    snackbarMessage: '',
    icons: {
      mdiCheckCircle,
      mdiCircleOutline,
      mdiContentSave,
      mdiHelp,
      mdiArrowLeft,
      mdiArrowRight,
      mdiInformation,
    }
  }),
  methods: {
    tagFilter(question) {
      const matchTags = [].concat(this.respondent_tags, this.answer_tags);
      let show = true;
      let matchedTags = [];
      if (question.inclusion_tags && Array.isArray(question.inclusion_tags) && question.inclusion_tags.length) {
        question.included_by_tag = false;
        show = false;
        for (const tag of question.inclusion_tags) {
          if (matchTags.indexOf(tag) > -1) {
            show = true;
            question.included_by_tag = true;
            matchedTags.push(tag);
          }
        }
      }
      if (question.exclusion_tags && Array.isArray(question.exclusion_tags) && question.exclusion_tags.length) {
        question.excluded_by_tag = false;
        for (const tag of question.exclusion_tags) {
          if (matchTags.indexOf(tag) > -1) {
            show = false;
            question.excluded_by_tag = true;
            matchedTags.push(tag);
          }
        }
      }
      if (question.inclusion_tags || question.exclusion_tags) {
        question.matched_tags = matchedTags;
      }
      return show;
    },
    uploadNotification(message) {
      this.snackbarMessage = message;
      this.snackbar = true;
      this.handleAnswer();
    },
    handleAnswer(triggerSave, triggerSubs, id, tags) {
      if (triggerSubs && !this.subs_triggers.includes(id)) {
        this.subs_triggers.push(id);
      } else {
        for (let i = 0; i < this.subs_triggers.length; i += 1) {
          if (this.subs_triggers[i] === id) {
            this.subs_triggers.splice(i, 1);
          }
        }
      }
      this.$emit('answer', triggerSave, id, tags);
      this.$nextTick(() => {
        this.refreshGroup(false);
      });
    },
    setGroup(area_id, group_id, validate) {
      this.$emit('checkdone');
      if (validate) {
        this.validateForm();
      } else {
        this.group.done = false;
      }
      if (validate && (!this.isValid || !this.group.done)) {
        this.target_area_id = area_id;
        this.target_group_id = group_id;
        this.dialog_not_completed = true;
      } else {
        this.dialog_not_completed = false;
        this.curr_area_id = area_id;
        this.curr_group_id = group_id;
        this.refreshGroup(true);
        this.scrollToTop();
      }
    },
    validateForm() {
      this.$refs.groupform.validate();
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    },
    refreshGroup(isScreenView) {
      this.prev_area_id = '';
      this.prev_group_id = '';
      this.prev_group_title = '';
      this.next_area_id = '';
      this.next_group_id = '';
      this.next_group_title = '';
      this.path = [];
      for (const area of this.areas) {
        area.active = false;
        if (area.id === this.curr_area_id) {
          area.active = true;
          this.area_title = this.translate(`a:${area.id}`, area.title);
          this.path.push({text: this.area_title});
          for (const group of area.groups) {
            if (group.id === this.curr_group_id) {
              this.group = group;
              this.group_title = this.translate(`g:${group.id}`, group.title);
              this.path.push({text: this.group_title});
              for (let idx = 0; idx < this.groups.length; idx++) {
                if (this.groups[idx].group_id === this.curr_group_id) {
                  if (idx > 0) {
                    this.prev_area_id = this.groups[idx - 1].area_id;
                    this.prev_group_id = this.groups[idx - 1].group_id;
                    this.prev_group_title = this.translate(`g:${this.groups[idx - 1].group_id}`, this.groups[idx - 1].group_title);
                  }
                  if (idx < this.groups.length - 1) {
                    this.next_area_id = this.groups[idx + 1].area_id;
                    this.next_group_id = this.groups[idx + 1].group_id;
                    this.next_group_title = this.translate(`g:${this.groups[idx + 1].group_id}`, this.groups[idx + 1].group_title);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};
</script>

<template>
  <div id="saq_front">
    <v-dialog
        v-model="dialog_welcome"
        @click:outside="closeWelcome()"
        max-width="700px"
    >
      <v-card>
        <div
            class="d-flex justify-center"
            v-if="logos.svg || logos.png"
        >
          <v-img
              :src="logos.svg || logos.png"
              max-height="100"
              max-width="200"
              class="mt-6"
              contain
          />
        </div>
        <v-card-title class="text-h3">
          {{ $t('welcome') }}
        </v-card-title>
        <v-card-text>
          <p v-html="saqStore.texts.welcome_text" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
              color="info"
              text
              @click="closeWelcome()"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row justify="center">
      <v-col cols="10">
        <h1 class="text-h2 mb-4">
          {{ $t('your_progress') }}
        </h1>
        <p
            class="mb-0"
            v-if="!done"
        >
          {{ $t('progress_text') }}
        </p>
        <p
            class="mb-0"
            v-if="done"
        >
          {{ $t('submit_text') }}
        </p>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="10">
        <v-progress-linear
            :value="(percentage_done === 0) ? 0 : percentage_done"
            color="success"
            rounded
            height="25"
            class="my-4"
        >
          <template #default="{ value }">
            <span class="white--text">{{ value }}%</span>
          </template>
        </v-progress-linear>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col
          cols="10"
          class="d-flex justify-center"
      >
        <v-btn
            @click="$emit('closefront')"
            outlined
            large
            color="info"
            class="mr-4"
        >
          {{ (percentage_done === 0) ? $t('button_start') : $t('continue_view_answers') }}
        </v-btn>
        <v-btn
            @click="$emit('submit')"
            v-if="done"
            outlined
            large
            color="success"
        >
          {{ $t('button_submit_answers') }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mdiCircleOutline, mdiCheckCircle, mdiContentSave, mdiHelp, mdiArrowLeft, mdiArrowRight} from '@mdi/js';
import {getSaqStore} from '../stores/saq.js';
import {isValueFalseOrZero} from '../utils';

export default {
  setup() {
    const saqStore = getSaqStore();
    return {
      saqStore,
    }
  },
  props: {
    areas: Array,
    welcomed: Boolean,
    submittable: Boolean,
    respondent_tags: Array,
    answer_tags: Array,
    logos: Object,
  },
  mounted() {
    if (this.welcomed || this.submittable) {
      this.isWelcomed = true;
    }
    this.parseStatus();
  },
  watch: {
    welcomed() {
      this.isWelcomed = this.welcomed;
    },
  },
  data: () => ({
    done: false,
    percentage_done: 0,
    questions_total: 0,
    questions_answered: 0,
    isWelcomed: false,
    icons: {
      mdiCheckCircle,
      mdiCircleOutline,
      mdiContentSave,
      mdiHelp,
      mdiArrowLeft,
      mdiArrowRight,
    }
  }),
  computed: {
    dialog_welcome: {
      get() {
        return !this.isWelcomed && !this.submittable;
      },
      set() {
        this.$emit('welcomeshown');
        this.isWelcomed = true;
      },
    },
  },
  methods: {
    tagFilter(question) {
      const matchTags = [].concat(this.respondent_tags, this.answer_tags);
      let show = true;
      let matchedTags = [];
      if (question.inclusion_tags && Array.isArray(question.inclusion_tags) && question.inclusion_tags.length) {
        question.included_by_tag = false;
        show = false;
        question.inclusion_tags.forEach(tag => {
          if (matchTags.indexOf(tag) > -1) {
            show = true;
            question.included_by_tag = true;
            matchedTags.push(tag);
          }
        });
      }
      if (question.exclusion_tags && Array.isArray(question.exclusion_tags) && question.exclusion_tags.length) {
        question.excluded_by_tag = false;
        question.exclusion_tags.forEach(tag => {
          if (matchTags.indexOf(tag) > -1) {
            show = false;
            question.excluded_by_tag = true;
            matchedTags.push(tag);
          }
        });
      }
      if (question.inclusion_tags || question.exclusion_tags) {
        question.matched_tags = matchedTags;
      }
      return show;
    },
    closeWelcome() {
      this.$emit('welcomeshown');
      this.isWelcomed = true;
    },
    parseStatus() {
      let qt = 0, qa = 0;
      const arrtype = ['mc', 'countries', 'unittable', 'files', 'sectors', 'companytable', 'certificatetable', 'principaltable', 'ownertable', 'customtable', 'suppliers', 'listcount'];
      for (const area of this.areas) {
        for (const group of area.groups) {
          let qgt = 0, qga = 0;
          for (const question of group.questions) {
            const isQuestionArrType = arrtype.includes(question.type) || (question.type === 'select' && !!question.multiselect);
            if (this.tagFilter(question)) {
              qt += 1;
              qgt += 1;
              if (isValueFalseOrZero(question.answer) || question.skipped) {
                if (question.skipped || !isQuestionArrType || (isQuestionArrType && Array.isArray(question.answer) && question.answer.length)) {
                  qa += 1;
                  qga += 1;
                }
              }
              if (question.subquestions && question.subs_required) {
                for (const subquestion of question.subquestions) {
                  const isSubQuestionArrType = arrtype.includes(subquestion.type) || (subquestion.type === 'select' && !!subquestion.multiselect);
                  if (this.tagFilter(subquestion)) {
                    qt += 1;
                    qgt += 1;
                    if (isValueFalseOrZero(subquestion.answer) || subquestion.skipped) {
                      if (subquestion.skipped || !isSubQuestionArrType || (isSubQuestionArrType && Array.isArray(subquestion.answer) && subquestion.answer.length)) {
                        qa += 1;
                        qga += 1;
                      }
                    }
                  }
                }
              }
            }
          }
          group.questions_total = qgt;
          group.questions_answered = qga;
          group.done = (qga === qgt);
        }
      }
      this.questions_total = qt;
      this.questions_answered = qa;
      this.percentage_done = (qt > 0) ? Math.floor((qa / qt) * 100) : 0;
      this.done = (qa === qt);
      if (this.done) {
        this.$emit('checkdone');
      }
    }
  }
}
</script>

<template>
  <v-input
      v-model="matrix"
      :rules="tableRule"
  >
    <v-data-table
        :headers="headers"
        :items="matrix"
        :no-data-text="$t('no_owners_added')"
        disable-sort
        disable-pagination
        disable-filtering
        hide-default-footer
        dense
        class="mt-3 fullwidth"
    >
      <template #footer>
        <v-toolbar
            flat
            dense
            color="white"
        >
          <v-spacer />
          <v-dialog
              v-model="dialog"
              max-width="800px"
          >
            <template #activator="{ on }">
              <v-btn
                  fab
                  small
                  color="info"
                  dark
                  v-on="on"
              >
                <v-icon>{{ icons.mdiPlus }}</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-form ref="itemtable">
                <v-card-title>
                  <span class="text-h3">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <v-radio-group
                          row
                          v-model="editedItem.type"
                      >
                        <v-radio
                            :label="$t('individual')"
                            value="individual"
                        />
                        <v-radio
                            :label="$t('company')"
                            value="company"
                        />
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                        cols="12"
                        sm="6"
                        v-if="editedItem.type === 'individual'"
                    >
                      <v-text-field
                          v-model.trim="editedItem.first_name"
                          :rules="requiredRule"
                          :label="$t('first_name')"
                          hide-details="auto"
                          outlined
                      />
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        v-if="editedItem.type === 'individual'"
                    >
                      <v-text-field
                          v-model.trim="editedItem.last_name"
                          :rules="requiredRule"
                          :label="$t('last_name')"
                          hide-details="auto"
                          outlined
                      />
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        v-if="editedItem.type === 'company'"
                    >
                      <v-text-field
                          v-model.trim="editedItem.company"
                          :rules="requiredRule"
                          :label="$t('company_name')"
                          hide-details="auto"
                          outlined
                      />
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        v-if="question.include.nationality"
                    >
                      <v-autocomplete
                          :items="localeCountries"
                          item-text="name"
                          item-value="code"
                          :placeholder="$t('nationality')"
                          :prepend-icon="icons.mdiEarth"
                          v-model="editedItem.nationality"
                          hide-details="auto"
                          outlined
                      />
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        v-if="question.include.ownership"
                    >
                      <v-text-field
                          v-model="editedItem.ownership"
                          type="number"
                          :label="$t('ownership_percent')"
                          hide-details="auto"
                          outlined
                      >
                        <template #append>
                          <div class="text-caption mt-1">
                            %
                          </div>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        v-if="question.include.incorporation_country"
                    >
                      <v-autocomplete
                          :items="localeCountries"
                          item-text="name"
                          item-value="code"
                          :placeholder="$t('country_of_incorporation')"
                          :prepend-icon="icons.mdiEarth"
                          v-model="editedItem.incorporation_country"
                          hide-details="auto"
                          outlined
                      />
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        v-if="question.include.date_acquisition"
                    >
                      <v-dialog
                          ref="datedialog1"
                          v-model="dialogDatePicker1"
                          :return-value.sync="editedItem.date_acquisition"
                          persistent
                          width="290px"
                      >
                        <template #activator="{ on, attrs }">
                          <v-text-field
                              v-model="editedItem.date_acquisition"
                              :label="$t('date_of_acquisition')"
                              :placeholder="$t('placeholder_date')"
                              :prepend-icon="icons.mdiCalendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              hide-details="auto"
                              outlined
                          />
                        </template>
                        <v-date-picker
                            v-model="editedItem.date_acquisition"
                            first-day-of-week="1"
                            scrollable
                        >
                          <v-spacer />
                          <v-btn
                              text
                              color="primary"
                              @click="dialogDatePicker1 = false"
                          >
                            {{
                              $t('button_cancel')
                            }}
                          </v-btn>
                          <v-btn
                              text
                              color="primary"
                              @click="$refs.datedialog1.save(editedItem.date_acquisition)"
                          >
                            {{ $t('button_ok') }}
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        v-if="question.include.date_birth && editedItem.type === 'individual'"
                    >
                      <v-dialog
                          ref="datedialog2"
                          v-model="dialogDatePicker2"
                          :return-value.sync="editedItem.date_birth"
                          persistent
                          width="290px"
                      >
                        <template #activator="{ on, attrs }">
                          <v-text-field
                              v-model="editedItem.date_birth"
                              :label="$t('date_of_birth')"
                              :placeholder="$t('placeholder_date')"
                              :prepend-icon="icons.mdiCalendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              hide-details="auto"
                              outlined
                          />
                        </template>
                        <v-date-picker
                            v-model="editedItem.date_birth"
                            first-day-of-week="1"
                            scrollable
                        >
                          <v-spacer />
                          <v-btn
                              text
                              color="primary"
                              @click="dialogDatePicker2 = false"
                          >
                            {{
                              $t('button_cancel')
                            }}
                          </v-btn>
                          <v-btn
                              text
                              color="primary"
                              @click="$refs.datedialog2.save(editedItem.date_birth)"
                          >
                            {{ $t('button_ok') }}
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        v-if="question.include.address"
                    >
                      <v-text-field
                          v-model.trim="editedItem.address"
                          :label="$t('address')"
                          hide-details="auto"
                          outlined
                      />
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        v-if="question.include.comment"
                    >
                      <v-text-field
                          v-model.trim="editedItem.comment"
                          :label="$t('comment')"
                          hide-details="auto"
                          outlined
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                      color="secondary"
                      text
                      @click="close"
                  >
                    {{ $t('button_cancel') }}
                  </v-btn>
                  <v-spacer />
                  <v-btn
                      color="info"
                      text
                      @click="save"
                  >
                    {{ $t('button_save') }}
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template #item.actions="{ item }">
        <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
        >
          {{ icons.mdiPencil }}
        </v-icon>
        <v-icon
            small
            @click="deleteItem(item)"
        >
          {{ icons.mdiDelete }}
        </v-icon>
      </template>
      <template #item.date_acquisition="{ item }">
        {{ item.date_acquisition ? new Date(item.date_acquisition).toLocaleDateString() : '' }}
      </template>
      <template #item.date_birth="{ item }">
        {{ item.date_birth ? new Date(item.date_birth).toLocaleDateString() : '' }}
      </template>
      <template #item.ownership="{ item }">
        {{ item.ownership || item.ownership === 0 ? `${item.ownership}%` : '' }}
      </template>
      <template #item.nationality="{ item }">
        {{ item.nationality.length === 3 ? localeCountries.find(i => i.code === item.nationality).name : '' }}
      </template>
      <template #item.incorporation_country="{ item }">
        {{ item.incorporation_country.length === 3 ? localeCountries.find(i => i.code === item.incorporation_country).name : '' }}
      </template>
    </v-data-table>
  </v-input>
</template>

<script>
import {mdiPencil, mdiDelete, mdiPlus, mdiEarth, mdiCalendar} from '@mdi/js';
import Countries from '../data/Countries.json';

export default {
  name: 'OwnerTable',
  props: {
    question: Object,
  },
  data: () => ({
    dialogDatePicker1: false,
    dialogDatePicker2: false,
    selectedDate: null,
    dialog: false,
    matrix: [],
    editedIndex: -1,
    localeCountries: [],
    editedItem: {
      type: 'individual',
      company: '',
      nationality: '',
      ownership: '',
      date_acquisition: '',
      date_birth: '',
      incorporation_country: '',
      address: '',
      comment: '',
    },
    defaultItem: {
      type: 'individual',
      company: '',
      nationality: '',
      ownership: '',
      date_acquisition: '',
      date_birth: '',
      incorporation_country: '',
      address: '',
      comment: '',
    },
    icons: {
      mdiPencil,
      mdiDelete,
      mdiPlus,
      mdiEarth,
      mdiCalendar,
    }
  }),
  computed: {
    requiredRule() {
      const required = this.$t('required');
      return [
        v => (!!v || v === 0) || required
      ];
    },
    tableRule() {
      const required = this.$t('required');
      return [
        v => !!v.length || required
      ];
    },
    headers() {
      const headers = [
        {text: this.$t('company_name'), value: 'company'},
        {text: this.$t('first_name'), value: 'first_name'},
        {text: this.$t('last_name'), value: 'last_name'},
      ];
      if (this.question.include.nationality) {
        headers.push({text: this.$t('nationality'), value: 'nationality'});
      }
      if (this.question.include.ownership) {
        headers.push({text: this.$t('ownership_percent'), value: 'ownership'});
      }
      if (this.question.include.incorporation_country) {
        headers.push({text: this.$t('country_of_incorporation'), value: 'incorporation_country'});
      }
      if (this.question.include.date_acquisition) {
        headers.push({text: this.$t('date_of_acquisition'), value: 'date_acquisition'});
      }
      if (this.question.include.date_birth) {
        headers.push({text: this.$t('date_of_birth'), value: 'date_birth'});
      }
      if (this.question.include.address) {
        headers.push({text: this.$t('address'), value: 'address'});
      }
      if (this.question.include.comment) {
        headers.push({text: this.$t('comment'), value: 'comment'});
      }
      headers.push({text: this.$t('actions'), value: 'actions'});
      return headers;
    },
    formTitle() {
      return this.editedIndex === -1 ? this.$t('add_owner') : this.$t('edit_owner');
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    parseCountryName(v) {
      return this.$t(`countryNames.${v.alpha3Code.toLowerCase()}`);
    },
    initialize() {
      this.matrix = (Array.isArray(this.question.answer)) ? this.question.answer : [];
      this.localeCountries = Countries.map(i => ({
        name: this.parseCountryName(i),
        code: i.alpha3Code,
      })).sort((a, b) => a.name.localeCompare(b.name));
    },
    editItem(item) {
      this.editedIndex = this.matrix.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      const index = this.matrix.indexOf(item);
      confirm(this.$t('confirm_delete_row')) && this.matrix.splice(index, 1);
      this.$emit('answer');
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.matrix[this.editedIndex], this.editedItem);
      } else {
        this.matrix.push(this.editedItem);
      }
      this.question.answer = this.matrix;
      this.$emit('answer');
      this.close();
    },
  },
};
</script>

<style scoped>
.fullwidth {
  width: 100%;
}
</style>

<template>
  <div class="certificate mt-3">
    <v-snackbar
      v-model="snack"
      :timeout="timeout"
    >
      {{ snackMessage }}
    </v-snackbar>
    <v-dialog
      v-model="dialogDeleteCertificateFile"
      max-width="400px"
    >
      <v-card>
        <v-card-title>{{ $t('delete_file') }}</v-card-title>
        <v-card-text>{{ $t('confirm_generic') }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="info"
            text
            @click="dialogDeleteCertificateFile = false"
          >
            {{ $t('button_no') }}
          </v-btn>
          <v-btn
            color="secondary"
            text
            @click="deleteFile"
          >
            {{
              $t('button_yes')
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-combobox
      v-if="!question.certificate_type && question.include.certificate_type"
      v-model="certificate.type"
      :items="saqStore.certificateTypes"
      :prepend-icon="icons.mdiCertificate"
      outlined
      :label="$t('type')"
      :placeholder="$t('placeholder_select_enter_type')"
      :rules="requiredRule"
    />
    <div
      class="d-flex mb-6"
      v-else
    >
      <v-icon style="margin-right: 9px;">
        {{ icons.mdiCertificate }}
      </v-icon>
      <span class="muted">{{ question.certificate_type }}</span>
    </div>
    <v-text-field
      v-if="question.include.location"
      v-model="certificate.location"
      :label="$t('location')"
      :placeholder="$t('placeholder_location')"
      :prepend-icon="icons.mdiMapMarker"
      outlined
    />
    <v-dialog
      ref="datedialog"
      v-if="question.include.expiration_date"
      v-model="dialogDatePicker"
      :return-value.sync="certificate.expiration_date"
      persistent
      width="290px"
    >
      <template #activator="{ on, attrs }">
        <v-text-field
          v-model="certificate.expiration_date"
          :label="$t('expiration_date')"
          :placeholder="$t('placeholder_date')"
          :prepend-icon="icons.mdiCalendar"
          :rules="question.require_expiration_date ? requiredRule : []"
          readonly
          outlined
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="certificate.expiration_date"
        first-day-of-week="1"
        scrollable
      >
        <v-spacer />
        <v-btn
          text
          color="primary"
          @click="dialogDatePicker = false"
        >
          {{ $t('button_cancel') }}
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="$refs.datedialog.save(certificate.expiration_date)"
        >
          {{
            $t('button_ok')
          }}
        </v-btn>
      </v-date-picker>
    </v-dialog>
    <v-text-field
      v-if="question.include.certificate_number"
      v-model="certificate.number"
      :label="$t('certificate_number')"
      :placeholder="$t('placeholder_certificate_number')"
      :prepend-icon="icons.mdiIdentifier"
      outlined
    />
    <div
      class="d-flex mb-8"
      v-if="question.uploads && question.uploads[0]"
      :key="filesKey"
    >
      <v-icon style="margin-right: 9px;">
        {{ icons.mdiPaperclip }}
      </v-icon>
      <v-chip
        close
        @click="downloadFile(question.uploads[0].id, question.uploads[0].original)"
        @click:close="confirmFileDelete(question.uploads[0].id, question.uploads[0].original)"
        color="info"
        dark
      >
        {{ question.uploads[0].original }}
      </v-chip>
    </div>
    <v-file-input
      v-else
      :label="$t('certificate_file')"
      outlined
      :placeholder="$t('placeholder_file')"
      :disabled="(!certificate.type && question.include.certificate_type) || (!certificate.expiration_date && question.require_expiration_date)"
      v-model="upload"
      :loading="uploading"
      show-size
      @change="uploadCertificate"
    >
      <template #progress>
        <v-progress-linear :value="progress" />
      </template>
    </v-file-input>
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */
import api from '../core-api'
import {mdiCalendar, mdiCertificate, mdiPaperclip, mdiIdentifier, mdiMapMarker} from '@mdi/js'
import {getSaqStore} from '../stores/saq';

export default {
  setup() {
    const saqStore = getSaqStore();
    return {
      saqStore,
    }
  },
  name: 'Certificate',
  props: {
    question: Object,
    respondent_id: String,
    supplier_id: String,
    saq_id: String,
    months: Array,
  },
  created() {
    if (this.question.answer) {
      this.certificate.type = this.question.answer.type;
      this.certificate.location = this.question.answer.location;
      this.certificate.number = this.question.answer.number;
      this.certificate.expiration_date = this.question.answer.expiration_date;
      if (!this.question.include || (this.question.include && Object.keys(this.question.include).length === 0)) {
        this.question.include = {
          certificate_type: true,
          location: true,
          expiration_date: true,
          certificate_number: true,
        };
      }
    }
    if (this.question.certificate_type) {
      this.certificate.type = this.question.certificate_type;
    }
  },
  data: () => ({
    upload: null,
    uploading: false,
    dialogDatePicker: false,
    dialogDeleteCertificateFile: false,
    selectedDate: null,
    timeout: 4000,
    snack: false,
    snackMessage: '',
    progress: 0,
    filesKey: 0,
    target_file_id: '',
    target_file_original: '',
    certificate: {
      type: '',
      location: '',
      number: '',
      expiration_date: '',
      file: '',
    },
    icons: {
      mdiCalendar,
      mdiCertificate,
      mdiPaperclip,
      mdiIdentifier,
      mdiMapMarker,
    },
  }),
  computed: {
    requiredRule() {
      const required = this.$t('required');
      return [
        v => (!!v || v === 0) || required
      ];
    },
    numberRule() {
      const required = this.$t('required');
      return [
        v => (!!v || v === 0) || required
      ];
    },
  },
  methods: {
    async downloadFile(id, original) {
      const url = await api.getSignedUrl(id, original);
      if (url) {
        window.location.href = url;
      }
    },
    confirmFileDelete(id, original) {
      this.target_file_id = id;
      this.target_file_original = original;
      this.dialogDeleteCertificateFile = true;
    },
    deleteFile() {
      this.dialogDeleteCertificateFile = false;
      api.deleteFile(this.target_file_id, this.target_file_original).then(result => {
        if (!result.success) {
          // Not handled
        }
      }).catch(err => {
        console.error('deleteFile', err);
      }).finally(() => {
        this.question.uploads = [];
        this.filesKey += 1;
        this.$emit('answer');
      });
    },
    uploadProgress(progress) {
      this.progress = progress;
    },
    async uploadCertificate() {
      if (this.upload) {
        this.uploading = true;
        try {
          let result = await api.storeCertificate(this.upload, this.respondent_id, this.saq_id, this.supplier_id, this.certificate.type, this.certificate.location, this.certificate.number, this.certificate.expiration_date, this.uploadProgress);
          if (result.length && result[0].success) {
            this.question.uploads = [];
            this.question.uploads.push({
              id: result[0].id,
              original: result[0].original,
            });
            this.certificate.file = result[0].id;
            this.question.answer = this.certificate;
            this.filesKey += 1;
            this.$emit('answer');
          } else {
            this.snackMessage = this.$t('notification_upload_failure');
            this.snack = true;
          }
        } catch (err) {
          this.snackMessage = this.$t('notification_upload_failure');
          this.snack = true;
        }
        this.uploading = false;
      }
    },
  },
}
</script>

<style scoped>
.muted {
  color: rgba(0, 0, 0, .6);
}
</style>

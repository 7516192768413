<template>
  <v-footer app class="footer">
      <a href="https://stratsys.com" target="_blank">
        Powered by <img src="https://frontend-packages.svc.stratsys.com/logos/icon-wordmark-dark.svg" alt="">
      </a>
      <v-spacer />
      <one-ux-link url="https://tool.evaluate.se/info/privacypolicy" text="Privacy Policy" open="new" />
  </v-footer>
</template>

<script>
export default {
  name: 'Footer',
  data: () => ({
    year: new Date().getFullYear().toString(),
  }),
}
</script>

<style scoped>
.footer {
  display: flex;
  flex: 1;
  align-items: center;
  gap: 1em;
  font: var(--one-ux-font--body-200);
  letter-spacing: var(--one-ux-letter-spacing--body-200);
  margin-bottom: .25em;
}

a {
  display: inline-flex;
  align-items: center;
  gap: .5em;
  text-decoration: none;
  &:link, &:visited, &:hover, &:active {
    color: var(--one-ux-palette--gray-700);
  }
}

img {
  height: 20px;
}
</style>

import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import i18n from './i18n';
import * as VueGoogleMaps from 'vue2-google-maps';
import {createPinia, PiniaVuePlugin} from 'pinia';
import VueMatomo from 'vue-matomo';

Vue.use(VueMatomo, {
  host: 'https://eye.ethosevaluate.net',
  siteId: 3,
  trackerFileName: 'matomo',
  router: router,
  enableLinkTracking: true,
  requireConsent: false,
  trackInitialView: true,
  disableCookies: false,
  requireCookieConsent: false,
  enableHeartBeatTimer: false,
  heartBeatTimerInterval: 15,
  debug: false,
  userId: undefined,
  cookieDomain: undefined,
  domains: undefined,
  preInitActions: [],
  trackSiteSearch: false
});

Vue.config.productionTip = false;

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

Vue.config.ignoredElements = [/^one-ux-/]

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBPdsMxMSfqzI03LwgN8XZYEJC_p9ON-zc',
    // libraries: 'places'
  },
  autobindAllEvents: false,
  installComponents: true,
});

new Vue({
  vuetify,
  i18n,
  router,
  pinia,
  render: h => h(App)
}).$mount('#app');



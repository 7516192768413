import {defineStore} from 'pinia';

const state = () => ({
    translation: {},
    texts: {},
    naceCodes: [],
    certificateTypes: [],
});

const getters = {
    getTranslation: (state) => (key) => state.translation[key],
};

const actions = {
}

export const getSaqStore = defineStore('saqStore', {
    state,
    getters,
    actions,
});